import {
    SelectItemProps,
    SelectPopoverProps,
    Role,
    Select,
    useSelectContext,
    Combobox,
    ComboboxProps,
    ComboboxListProps,
    SelectListProps,
    SelectItemCheck
} from '@ariakit/react'
import {
    StyledSelectArrow,
    StyledSelectComboboxList,
    StyledSelectComboboxPopover,
    StyledSelectItem,
    StyledSelectItemContent,
    StyledSelectList,
    StyledSelectPopover,
    StyledSelectTrigger,
    StyledSelectVirtualList
} from '@components/ui/select-atoms/style'
import {forwardRef, ReactNode} from 'react'
import {ButtonProps} from '@components/ui/button/Button.tsx'
import {ChevronDownIcon, XCloseIcon} from '@components/ui/icon/Icon.tsx'
import {raise} from '@utilities/helpers.ts'
import {Scrollbar} from '@components/ui/scrollbar/Scrollbar.tsx'
import {ScrollArea} from '@components/ui/scroll-area/ScrollArea.tsx'
import {InputText} from '@/components/commons/input-text/InputText'
import {VirtuosoProps} from 'react-virtuoso'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const SelectPopover = (props: SelectPopoverProps) => <StyledSelectPopover gutter={4} {...props} />

export const SelectComboboxPopover = (props: SelectPopoverProps) => (
    <StyledSelectComboboxPopover gutter={4} sameWidth {...props} />
)

export const SelectList = (props: SelectListProps) => (
    <ScrollArea scrollbar={<Scrollbar />}>
        <StyledSelectList {...props} />
    </ScrollArea>
)

export const SelectComboboxList = (props: ComboboxListProps) => <StyledSelectComboboxList {...props} />

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const SelectVirtualList = (props: VirtuosoProps<any, any>) => <StyledSelectVirtualList {...props} />

export const SelectItem = ({children, ...props}: SelectItemProps) => (
    <StyledSelectItem {...props}>
        <StyledSelectItemContent>{children}</StyledSelectItemContent>
        <SelectItemCheck />
    </StyledSelectItem>
)

export const SelectComboboxInput = forwardRef<HTMLInputElement, ComboboxProps>((props, ref) => (
    <Combobox autoSelect render={<InputText ref={ref} type="text" inputSize="sm" />} {...props} />
))

export interface SelectTriggerProps extends Omit<ButtonProps, 'children'> {
    placeholder?: ReactNode
    displayValue: (value: string | string[]) => ReactNode
    hasError?: boolean
    typeIcon?: ReactNode
    onClear?: () => void
}

export const SelectTrigger = forwardRef<HTMLSelectElement, SelectTriggerProps>(
    ({placeholder, typeIcon, displayValue, hasError = false, onClear, ...rest}, ref) => {
        const selectContext = useSelectContext() ?? raise('SelectTrigger must be in a SelectProvider')
        const value = selectContext.useState('value')
        const isOpen = selectContext.useState('open')
        const children = value.length >= 1 ? displayValue(value) : undefined

        return (
            <Select
                render={
                    <Role.select
                        ref={ref}
                        render={
                            <StyledSelectTrigger
                                $hasChildren={!!children}
                                type="button"
                                variant="secondary"
                                $hasError={hasError}
                                {...rest}
                            >
                                <Flexbox fullWidth gap={2}>
                                    {typeIcon}
                                    {children || placeholder}
                                </Flexbox>
                                {onClear ? (
                                    <XCloseIcon
                                        onClick={event => {
                                            event.stopPropagation()
                                            onClear()
                                        }}
                                        size={16}
                                    />
                                ) : (
                                    <StyledSelectArrow $isOpen={isOpen} render={<ChevronDownIcon />} />
                                )}
                            </StyledSelectTrigger>
                        }
                    />
                }
            />
        )
    }
)
