import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {CreateTicketPayload, TicketSmall} from '@/features/tickets/types.ts'
import {httpCreateTicket} from '@/features/tickets/services/tickets.http.ts'
import {errorHandler} from '@utilities/genericErrorHandler.ts'
import {parseAxiosPromise} from '@/axiosInstance.ts'
import {captureException} from '@sentry/react'

export const useCreateTicket = (
    options?: Omit<UseMutationOptions<TicketSmall, unknown, CreateTicketPayload>, 'mutationFn'>
) => {
    const queryClient = useQueryClient()
    return useMutation({
        ...options,
        mutationKey: [MUTATION_KEYS.CREATE_TICKET],
        mutationFn: payload =>
            parseAxiosPromise({
                axiosPromise: httpCreateTicket(payload),
                responseShape: TicketSmall,
                onZodError: captureException
            }),
        onError: (error, variables, context) => {
            errorHandler(error)
            options?.onError?.(error, variables, context)
        },
        onSuccess: (data, variables, context) => {
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.TICKETS]})
            options?.onSuccess?.(data, variables, context)
        }
    })
}
