import {ComboboxItem, ComboboxProvider, SelectProvider} from '@ariakit/react'
import {
    SelectComboboxInput,
    SelectComboboxList,
    SelectComboboxPopover,
    SelectItem,
    SelectTrigger
} from '@components/ui/select-atoms/SelectAtoms.tsx'
import {FC} from 'react'
import {InputHelpText} from '@components/ui/input-help-text/InputHelpText.tsx'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useTranslation} from 'react-i18next'
import {Label} from '@components/ui/label/Label.tsx'
import {useGetAssignable} from '@/features/tickets/services/query/useGetAssignable.ts'
import {useInputSearch} from '@hooks/useInputSearch.ts'
import {useAuthStore} from '@/features/auth/store/store.ts'
import {Ticket} from '@/features/tickets/types.ts'

interface RoleFilterSelectProps {
    value: Ticket['assignee']
    onChange: (value: string) => void
    disabled?: boolean
    isOptional?: boolean
    onClear?: () => void
}
export const AssigneeSelect: FC<RoleFilterSelectProps> = ({value, onChange, disabled, isOptional, onClear}) => {
    const {t} = useTranslation()
    const {searchValue, onSearch} = useInputSearch('')
    const query = useGetAssignable({searchString: searchValue})
    const user = useAuthStore(state => state.user)
    const remappedData = (query.data || []).sort((a, b) => {
        if (a.id.toString() == user?.id.toString()) return -1
        if (b.id.toString() == user?.id.toString()) return 1
        return 0
    })

    return (
        <ComboboxProvider setValue={onSearch} defaultValue={searchValue} resetValueOnHide>
            <SelectProvider setValue={onChange} value={value?.id.toString() || ''}>
                <Flexbox direction={'column'} fullWidth gap={1.5}>
                    <Label>
                        {t('tickets:aside:assignee_select:label')}{' '}
                        {isOptional && <span>({t('commons:optional')})</span>}
                    </Label>
                    <SelectTrigger
                        onClear={value ? onClear : undefined}
                        disabled={disabled}
                        variant={'ghost'}
                        fullWidth
                        displayValue={() => (
                            <Flexbox gap={1}>
                                <p>{value?.firstName}</p>
                                <p>{value?.lastName}</p>
                                <p>{user?.id == value?.id && `(${t('commons:you')})`}</p>
                            </Flexbox>
                        )}
                        placeholder={t('tickets:aside:assignee_select:label')}
                    />
                </Flexbox>
                <SelectComboboxPopover sameWidth>
                    <SelectComboboxInput />
                    <SelectComboboxList>
                        {query.isLoading ? (
                            <Flexbox direction="column" gap={1}>
                                {Array.from({length: 3}, (_, index) => (
                                    <Skeleton key={index} height={32} />
                                ))}
                            </Flexbox>
                        ) : query.isError ? (
                            <InputHelpText error={t('errors:default')} />
                        ) : remappedData.length == 0 ? (
                            <InputHelpText helpText={t('commons:no_results')} />
                        ) : (
                            remappedData.map(option => (
                                <SelectItem render={<ComboboxItem />} value={option.id.toString()} key={option.id}>
                                    <Flexbox gap={1}>
                                        <p>{option.firstName}</p>
                                        <p>{option.lastName}</p>
                                        <p>{user?.id == option.id && `(${t('commons:you')})`}</p>
                                    </Flexbox>
                                </SelectItem>
                            ))
                        )}
                    </SelectComboboxList>
                </SelectComboboxPopover>
            </SelectProvider>
        </ComboboxProvider>
    )
}
