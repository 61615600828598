import styled, {css} from 'styled-components'

interface StyledTabButtonProps {
    $isActive: boolean
}
export const StyledTabButton = styled.button<StyledTabButtonProps>`
    ${({$isActive, theme: {spacing, palette}}) => css`
        cursor: pointer;
        padding: ${spacing * 4}px ${spacing * 3}px;
        text-align: center;
        ${$isActive &&
        css`
            border-bottom: 2px solid ${palette.primary['600']};
            color: ${palette.primary['600']};
        `}
    `}
`
