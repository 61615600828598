import React from 'react'
import {DashboardPageLayout} from '@pages/dashboard/style.ts'
import {useTranslation} from 'react-i18next'
import {useGetDashboardData} from '@/features/dashboard/services/query/useGetDashboardData.ts'
import {SelectValue} from '@components/commons/select/Select.tsx'
import {SingleValue} from 'react-select'
import {DashboardDataSkeleton} from '@/features/dashboard/component/dashboard-data/DashboardDataSkeleton.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {DashboardData} from '@/features/dashboard/component/dashboard-data/DashboardData.tsx'
import {DateFilterSelect} from '@components/commons/date-filter-select/DateFilterSelect.tsx'
import {findDateRangeKey, getDateOptionByValue, selectDateValue} from '@components/commons/date-filter-select/utils.ts'
import {adaptTypeSearchParamsToSelect} from '@components/commons/user-type-filter-select/utils.ts'
import {UserTypeFilterSelect} from '@components/commons/user-type-filter-select/UserTypeFilterSelect.tsx'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {AlertCircleIcon} from '@components/ui/icon/Icon.tsx'

export const Dashboard: React.FC = () => {
    const {t} = useTranslation()
    const {remappedData, isLoading, setSearchParams, searchParams, isError} = useGetDashboardData()
    const onChangeDateSelect = (newValue: string) => {
        if (newValue) {
            setSearchParams(selectDateValue[newValue])
        }
    }
    const onChangeTypeSelect = (newValue: SingleValue<SelectValue>) => {
        if (newValue) {
            setSearchParams({type: newValue.value})
        }
    }
    return (
        <DashboardPageLayout
            title={t('dashboard:title')}
            sideHeading={
                <Flexbox align={'center'} gap={4}>
                    <UserTypeFilterSelect
                        defaultValue={searchParams.type ? adaptTypeSearchParamsToSelect(searchParams.type) : undefined}
                        onChange={onChangeTypeSelect}
                    />
                    <DateFilterSelect
                        defaultValue={getDateOptionByValue(findDateRangeKey(searchParams))}
                        onChange={onChangeDateSelect}
                    />
                </Flexbox>
            }
        >
            {isError ? (
                <ErrorBox icon={<AlertCircleIcon />} title={t('errors:default')} />
            ) : !isLoading && remappedData ? (
                <DashboardData data={remappedData} searchParams={searchParams} />
            ) : (
                <DashboardDataSkeleton />
            )}
        </DashboardPageLayout>
    )
}

Dashboard.displayName = 'Dashboard'
