import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient'
import {parseAxiosPromise} from '@/axiosInstance.ts'
import {captureException} from '@sentry/react'
import {httpGetTicketDetails} from '@/features/tickets/services/tickets.http.ts'
import {Ticket} from '@/features/tickets/types.ts'
import {raise} from '@utilities/helpers.ts'

export const useGetTicketsDetails = (ticketId?: number) => {
    return useQuery({
        queryKey: [QUERY_KEYS.TICKET_DETAILS, ticketId],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetTicketDetails({
                    ticketId: ticketId || raise('ticketId is undefined'),
                    responseType: 'regular'
                }),
                onZodError: captureException,
                responseShape: Ticket
            }),
        enabled: !!ticketId
    })
}
