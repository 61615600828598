import {FC, useEffect, useMemo} from 'react'
import {Table} from '@components/commons/table/Table.tsx'
import useWindowDimensions from '@hooks/useWindowDimensions.ts'
import {useTranslation} from 'react-i18next'
import {TableDataCell} from '@components/commons/table/TableDataCell/TableDataCell.tsx'
import {capitalize} from '@utilities/helpers.ts'
import {SearchLgIcon} from '@components/ui/icon/Icon.tsx'
import {EmptySearch} from '@components/commons/empty-search/EmptySearch.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {ticketFormatData} from '@/features/tickets/utils.ts'
import {useTicketsTable} from '@/features/tickets/services/query/useTicketsTable.ts'
import {useTicketAsideStore} from '@/features/tickets/store/useTicketAside.ts'

interface TicketsTableProps {
    onResetSearchCb?: () => void
}
export const TicketsTable: FC<TicketsTableProps> = ({onResetSearchCb}) => {
    const {
        remappedData: tickets,
        searchValue,
        fetchNextPage,
        isLoading,
        isError,
        isFetchingNextPage,
        sorter
    } = useTicketsTable()
    const {width} = useWindowDimensions()
    const isMobile = useMemo(() => width < 960, [width])
    const {t} = useTranslation()
    const setSlidingModalState = useTicketAsideStore(state => state.setSlidingModalState)
    const setView = useTicketAsideStore(state => state.setView)
    const setTicketId = useTicketAsideStore(state => state.setTicketId)
    const slidingModalState = useTicketAsideStore(state => state.slidingModalState)
    const ticketTableIndex = useTicketAsideStore(state => state.ticketTableIndex)
    const onCloseAside = useTicketAsideStore(state => state.onCloseAside)
    const setTicketTableIndex = useTicketAsideStore(state => state.setTicketTableIndex)
    const onClickRow = (itemIndex: number) => {
        setTicketTableIndex(itemIndex)
        setTicketId(tickets[itemIndex].id)
        setView('detail')
    }

    useEffect(() => {
        let isKeyPressed = false

        const handleKeyDown = (event: KeyboardEvent) => {
            if (isKeyPressed) return
            isKeyPressed = true

            const {ticketTableIndex, setTicketTableIndex} = useTicketAsideStore.getState()
            if (ticketTableIndex === null) return

            if (event.key === 'ArrowUp' && ticketTableIndex > 0) {
                event.preventDefault()
                setTicketTableIndex(ticketTableIndex - 1)
                setTicketId(tickets[ticketTableIndex - 1].id)
            } else if (event.key === 'ArrowDown' && ticketTableIndex < tickets.length - 1) {
                event.preventDefault()
                setTicketTableIndex(ticketTableIndex + 1)
                setTicketId(tickets[ticketTableIndex + 1].id)
            }

            setTimeout(() => {
                isKeyPressed = false
            }, 500)
        }

        window.addEventListener('keydown', handleKeyDown)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [tickets.length])

    return (
        <Table
            data={tickets}
            isMobile={isMobile}
            isLoading={isLoading}
            onChangePage={fetchNextPage}
            isChangingPage={isFetchingNextPage}
            isError={isError}
            searchValue={searchValue}
            selectedRowIndex={ticketTableIndex}
            sorter={{
                ...sorter,
                onSort: params => {
                    slidingModalState == 'open' && onCloseAside()
                    sorter.onSort(params)
                }
            }}
            onClickRow={slidingModalState == 'open' ? onClickRow : undefined}
            columns={[
                {
                    label: t('tickets:table:labels:id'),
                    width: '10%',
                    sortName: 'id',
                    cellRender: (ticket, index) => (
                        <TableDataCell>
                            <Button
                                variant={'tertiary'}
                                onClick={() => {
                                    setTicketTableIndex(index)
                                    setSlidingModalState('open')
                                    setView('detail')
                                    setTicketId(ticket.id)
                                }}
                            >
                                {`#${ticket.id}`}
                            </Button>
                        </TableDataCell>
                    )
                },
                {
                    label: t('tickets:table:labels:typology'),
                    width: '10%',
                    sortName: 'type',
                    cellRender: ticket => (
                        <TableDataCell>
                            <p>{t(`tickets:table:typology:${ticket.type}`)}</p>
                        </TableDataCell>
                    )
                },
                {
                    label: t('tickets:table:labels:reported_by'),
                    width: '25%',
                    sortName: 'sender',
                    cellRender: ticket => (
                        <TableDataCell>
                            <h4>{`${capitalize(ticket.senderFirstName)} ${capitalize(ticket.senderLastName)}`}</h4>
                            <span title={ticket.email}>{ticket.email}</span>
                        </TableDataCell>
                    )
                },
                {
                    label: t('tickets:table:labels:assignee'),
                    width: '20%',
                    sortName: 'assignee',
                    cellRender: ticket => (
                        <TableDataCell>
                            {ticket.assignee ? (
                                <h4>{`${capitalize(ticket.assignee.firstName)} ${capitalize(ticket.assignee.lastName)}`}</h4>
                            ) : (
                                <span>{t('tickets:table:no_assignee')}</span>
                            )}
                        </TableDataCell>
                    )
                },
                {
                    label: t('tickets:table:labels:departments'),
                    width: '20%',
                    sortName: 'department',
                    cellRender: ticket => (
                        <TableDataCell>
                            {ticket.departments.length > 0 ? (
                                <p title={ticket.departments[0].name}>{ticket.departments[0].name}</p>
                            ) : (
                                <span>{t('tickets:table:no_department')}</span>
                            )}
                        </TableDataCell>
                    )
                },
                {
                    label: t('tickets:table:labels:created_at'),
                    width: '15%',
                    sortName: 'createdAt',
                    cellRender: ticket => (
                        <TableDataCell>
                            <h4>{ticketFormatData(ticket.createdAt)}</h4>
                        </TableDataCell>
                    )
                }
            ]}
            emptySearchStateComponent={
                <EmptySearch
                    title={t('commons:no_results')}
                    icon={<SearchLgIcon />}
                    onClearSearch={() => onResetSearchCb && onResetSearchCb()}
                />
            }
        />
    )
}
