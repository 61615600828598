import React, {ChangeEvent, useEffect, useRef, useState} from 'react'
import {useTicketsTable} from '@/features/tickets/services/query/useTicketsTable.ts'
import {TicketsTable} from '@/features/tickets/components/tickets-table/TicketsTable.tsx'
import {useTranslation} from 'react-i18next'
import {GridIcon, SearchLgIcon} from '@components/ui/icon/Icon.tsx'
import {InputText} from '@components/commons/input-text/InputText.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {
    StyledExportButton,
    StyledTicketPageHeaderContainer,
    StyledTicketPageHeaderWrapper,
    StyleTabSection
} from '@pages/tickets/style.ts'
import {EmptyTickets} from '@/features/tickets/components/empty-tickets/EmptyTickets.tsx'
import {useAsync} from '@hooks/useAsync.ts'
import {httpExportTickets} from '@/features/tickets/services/tickets.http.ts'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {palette} from '@/theme/palette.ts'
import {generateCsvDownload} from '@utilities/helpers.ts'
import {findDateRangeKey, getDateOptionByValue, selectDateValue} from '@components/commons/date-filter-select/utils.ts'
import {DateFilterSelect} from '@components/commons/date-filter-select/DateFilterSelect.tsx'
import {TabButton} from '@components/ui/tab-button/TabButton.tsx'
import {StyledPageHeaderText} from '@components/commons/page-hero/style.ts'
import {StyledPageContainer} from '@layouts/page-layout/style.ts'
import {Checkbox} from '@components/commons/checkbox/ChekBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {TicketAside} from '@/features/tickets/components/ticket-aside/TicketAside.tsx'
import {useTicketAsideStore} from '@/features/tickets/store/useTicketAside.ts'
import {useAuthStore} from '@/features/auth/store/store.ts'

export const Tickets: React.FC = () => {
    const {t} = useTranslation()
    const searchRef = useRef<HTMLInputElement | null>(null)
    const user = useAuthStore(state => state.user)

    const {
        remappedData: tickets,
        onSearch,
        searchValue,
        isPending,
        isError,
        onResetSearch,
        searchParams,
        setSearchParams,
        toggleCompletedHandler
    } = useTicketsTable()
    const {run: runExport, isLoading: isLoadingExport} = useAsync()
    const [currentSection, setCurrentSection] = useState<'open' | 'closed'>(
        searchParams.isCompleted ? (searchParams.isCompleted === 'false' ? 'open' : 'closed') : 'open'
    )
    const slidingModalState = useTicketAsideStore(state => state.slidingModalState)
    const setSlidingModalState = useTicketAsideStore(state => state.setSlidingModalState)
    const setTicketId = useTicketAsideStore(state => state.setTicketId)
    const setView = useTicketAsideStore(state => state.setView)
    const onCloseAside = useTicketAsideStore(state => state.onCloseAside)
    const setTicketTableIndex = useTicketAsideStore(state => state.setTicketTableIndex)

    const onClickExport = async () => {
        const exportData = await runExport(
            httpExportTickets({
                page: 1,
                limit: tickets.length,
                startDate: searchParams.startDate,
                endDate: searchParams.endDate,
                responseType: 'regular'
            })
        )
        generateCsvDownload({data: exportData.data, fileName: 'Ticket'})
    }

    const onChangeDateSelect = (newValue: string) => {
        slidingModalState == 'open' && onCloseAside()
        if (newValue) {
            setSearchParams(selectDateValue[newValue])
        }
    }
    const onClickOpenTab = () => {
        if (slidingModalState == 'open') {
            onCloseAside()
        }
        setCurrentSection('open')
        toggleCompletedHandler(false)
    }
    const onClickClosedTab = () => {
        if (slidingModalState == 'open') {
            onCloseAside()
        }
        slidingModalState == 'open' && onCloseAside()
        setCurrentSection('closed')
        toggleCompletedHandler(true)
    }

    const onClickAddTicket = () => {
        setSlidingModalState('open')
        setTicketTableIndex(null)
        setView('create')
    }

    const onChangeAssignedToMe = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.checked) {
            setSearchParams({assigneeId: user?.id})
        } else setSearchParams({assigneeId: undefined})
        slidingModalState == 'open' && onCloseAside()
    }

    useEffect(() => {
        if (searchParams.ticketId) {
            setSlidingModalState('open')
            setTicketId(searchParams.ticketId)
            setView('detail')
        }
        return () => {
            setSlidingModalState('closed')
            setTicketId(null)
            setTicketTableIndex(null)
        }
    }, [])

    return (
        <StyledPageContainer fullWidth>
            <StyledTicketPageHeaderWrapper direction={'column'} align={'stretch'} gap={6}>
                <StyledTicketPageHeaderContainer>
                    <StyledPageHeaderText>
                        <Flexbox align={'center'} gap={2}>
                            <h1>{t('tickets:title')}</h1>
                        </Flexbox>
                    </StyledPageHeaderText>
                    <Flexbox align={'center'} gap={3}>
                        <StyledExportButton variant={'secondary'} onClick={onClickExport} disabled={isLoadingExport}>
                            {isLoadingExport ? (
                                <Spinner color={palette.light.primary['700']} size={20} />
                            ) : (
                                <GridIcon />
                            )}

                            <p>{t('tickets:export')}</p>
                        </StyledExportButton>
                        <Button onClick={onClickAddTicket}>{t('tickets:add_ticket')}</Button>
                    </Flexbox>
                </StyledTicketPageHeaderContainer>
                <Flexbox gap={4} align={'center'}>
                    <InputText
                        onChange={e => onSearch(e.currentTarget.value)}
                        defaultValue={searchValue}
                        ref={searchRef}
                        type={'text'}
                        placeholder={t('commons:search_placeholder')}
                        typeIcon={<SearchLgIcon size={18} />}
                        inputSize={'sm'}
                        width={'320px'}
                    />
                    <DateFilterSelect
                        defaultValue={'any_date'}
                        value={getDateOptionByValue(findDateRangeKey(searchParams))}
                        onChange={onChangeDateSelect}
                        haveAnyDate
                    />
                    <Checkbox
                        onChange={onChangeAssignedToMe}
                        checked={!!searchParams.assigneeId}
                        id={'assigned_to_me'}
                        label={t('tickets:assigned_to_me')}
                    />
                </Flexbox>
                <StyleTabSection>
                    <Flexbox>
                        <TabButton onClick={onClickOpenTab} $isActive={currentSection === 'open'}>
                            {t('tickets:open')}
                        </TabButton>
                        <TabButton onClick={onClickClosedTab} $isActive={currentSection === 'closed'}>
                            {t('tickets:closed')}
                        </TabButton>
                    </Flexbox>
                </StyleTabSection>
            </StyledTicketPageHeaderWrapper>

            {tickets.length === 0 && !isPending && !isError && !searchValue ? (
                <EmptyTickets
                    title={t('tickets:emptyTickets:title')}
                    description={t('tickets:emptyTickets:subtitle', {
                        data: currentSection === 'open' ? 'aperto' : 'chiuso'
                    })}
                />
            ) : (
                <TicketsTable onResetSearchCb={() => onResetSearch(searchRef)} />
            )}
            {slidingModalState != 'closed' && <TicketAside />}
        </StyledPageContainer>
    )
}

Tickets.displayName = 'Tickets'
