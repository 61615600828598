import {
    StyledAsideContentWrapper,
    StyledAsideFooter,
    StyledAsideHeader,
    StyledAsideSection
} from '@/features/tickets/components/atoms/style.ts'
import {FlexboxProps} from '@components/ui/flexbox/FlexBox.tsx'
import {HTMLAttributes} from 'react'

export const AsideContentWrapper = (props: HTMLAttributes<HTMLDivElement>) => <StyledAsideContentWrapper {...props} />

export const AsideHeader = (props: FlexboxProps<'div'>) => <StyledAsideHeader {...props} />

export const AsideFooter = (props: FlexboxProps<'div'>) => <StyledAsideFooter {...props} />

export const AsideSection = (props: FlexboxProps<'div'>) => <StyledAsideSection {...props} />
