import * as RadixScrollArea from '@radix-ui/react-scroll-area'
import {forwardRef, ReactNode, RefAttributes} from 'react'
import {ScrollAreaProps as RadixScrollAreaProps, ScrollAreaViewportProps} from '@radix-ui/react-scroll-area'
import {Scrollbar} from '@components/ui/scrollbar/Scrollbar.tsx'

type ScrollAreaProps = {
    children: ReactNode
    scrollbar: ReturnType<typeof Scrollbar>
    viewportProps?: ScrollAreaViewportProps & RefAttributes<HTMLDivElement>
} & RadixScrollAreaProps

export const ScrollArea = forwardRef<HTMLDivElement, ScrollAreaProps>(
    ({children, scrollbar, viewportProps, ...rest}, ref) => {
        return (
            <RadixScrollArea.Root scrollHideDelay={500} ref={ref} {...rest}>
                <RadixScrollArea.Viewport style={{height: '100%'}} {...viewportProps}>
                    {children}
                </RadixScrollArea.Viewport>
                {scrollbar}
            </RadixScrollArea.Root>
        )
    }
)
