import {MUTATION_KEYS, QUERY_KEYS, queryClient} from '@/queryClient.ts'
import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {errorHandler} from '@utilities/genericErrorHandler.ts'
import {raise} from '@utilities/helpers.ts'
import {httpEditTicket} from '@/features/tickets/services/tickets.http.ts'
import {HttpEditTicketParams} from '@/features/tickets/types.ts'

export const useEditTicket = (
    ticketId?: number,
    options?: Omit<UseMutationOptions<unknown, unknown, HttpEditTicketParams['data']>, 'mutationFn'>
) => {
    return useMutation({
        ...options,
        mutationKey: [MUTATION_KEYS.UPDATE_TICKET],
        mutationFn: data => httpEditTicket({ticketId: ticketId || raise('ticketID is undefined'), data}),
        onError: (error, variables, context) => {
            errorHandler(error)
            options?.onError?.(error, variables, context)
        },
        onSuccess: async (data, variables, context) => {
            await queryClient.invalidateQueries({queryKey: [QUERY_KEYS.TICKETS]})
            await queryClient.invalidateQueries({queryKey: [QUERY_KEYS.TICKET_DETAILS, ticketId]})
            options?.onSuccess?.(data, variables, context)
        }
    })
}
