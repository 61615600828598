import {Controller, useFormContext} from 'react-hook-form'
import {CREATE_USER_FORM_MODEL, CreateUserValidationSchema} from '../CreateUserFormModel'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Select, SelectValue} from '@components/commons/select/Select.tsx'
import {
    StyledCreateUserFormWrapper,
    StyledInfo,
    StyledPermissionErrorBox
} from '@/features/users/components/create-user-form/style.ts'
import {AlertCircleIcon, XCloseIcon} from '@components/ui/icon/Icon.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Checkbox} from '@components/commons/checkbox/ChekBox.tsx'

interface PermissionsUserFormProps {
    permissions: SelectValue[]
    isUpdate?: boolean
}
export const PermissionsUserForm: React.FC<PermissionsUserFormProps> = ({permissions, isUpdate = false}) => {
    const [showErrorBox, setShowErrorBox] = useState<boolean>(false)
    const {t} = useTranslation()
    const {control, watch, register} = useFormContext<CreateUserValidationSchema>()
    const permissionsWatch = watch(CREATE_USER_FORM_MODEL.permissions.name)

    useEffect(() => {
        if (permissionsWatch && permissionsWatch.length < 1) {
            setShowErrorBox(true)
        } else {
            setShowErrorBox(false)
        }
    }, [permissionsWatch])

    return (
        <StyledCreateUserFormWrapper>
            <Flexbox direction={'column'} gap={2}>
                <h4>{t('users:createModal:permit_title')}</h4>
                <p>{t('users:createModal:permit_subtitle')}</p>
            </Flexbox>
            <Controller
                render={({field: {onChange, value}}) => (
                    <Select
                        value={value}
                        options={permissions}
                        onChange={newValue => {
                            onChange(newValue ? (newValue as SelectValue[]) : [])
                        }}
                        isMulti={true}
                        name={CREATE_USER_FORM_MODEL.permissions.name}
                        label={t(CREATE_USER_FORM_MODEL.permissions.label)}
                        isClearable={false}
                        isSearchable={true}
                        placeholder={t(CREATE_USER_FORM_MODEL.permissions.placeholder)}
                        helpText={t(CREATE_USER_FORM_MODEL.permissions.helpText)}
                    />
                )}
                control={control}
                name={CREATE_USER_FORM_MODEL.permissions.name}
            />
            {showErrorBox && (
                <StyledPermissionErrorBox
                    gap={4}
                    justify={'space-between'}
                    align={'center'}
                    onClick={() => setShowErrorBox(false)}
                >
                    <AlertCircleIcon />
                    <p>{t('users:createModal:form:errors:permissions_error')}</p>
                    <Button shape={'square'} variant={'ghost'} size={'sm'}>
                        <XCloseIcon />
                    </Button>
                </StyledPermissionErrorBox>
            )}
            {permissionsWatch?.find(permission => permission.label == 'Ticket') && (
                <Checkbox
                    id={'isAssignable'}
                    label={t(CREATE_USER_FORM_MODEL.isAssignable.label)}
                    {...register(CREATE_USER_FORM_MODEL.isAssignable.name)}
                />
            )}
            {!isUpdate && (
                <StyledInfo>
                    <p>{t('users:createModal:info')}</p>
                </StyledInfo>
            )}
        </StyledCreateUserFormWrapper>
    )
}
