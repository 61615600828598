import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {errorHandler} from '@utilities/genericErrorHandler.ts'
import {raise} from '@utilities/helpers.ts'
import {httpRemoveTicketDelegation} from '@/features/tickets/services/tickets.http.ts'

export const useRemoveTicketDelegation = (ticketId?: number, options?: Omit<UseMutationOptions, 'mutationFn'>) => {
    return useMutation({
        ...options,
        mutationFn: () => httpRemoveTicketDelegation({ticketId: ticketId || raise('ticketID is undefined')}),
        onError: (error, variables, context) => {
            errorHandler(error)
            options?.onError?.(error, variables, context)
        }
    })
}
