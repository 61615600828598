import {AsideFooter, AsideSection} from '@/features/tickets/components/atoms/TicketsAtoms.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {
    InputFileHighlightedPlaceholder,
    InputFilePlaceholder,
    InputFileValue,
    StyledOpenTicketModalRadioGrid,
    StyledRemoveFileButton,
    StyledSectionTitle
} from '@/features/tickets/components/ticket-form-aside-content/style.ts'
import {Label} from '@components/ui/label/Label.tsx'
import {
    ACCEPTED_FILE_TYPES,
    adaptTicketFromFormToCreateMutation,
    adaptTicketFromFormToEditMutation,
    MAX_CONTENT_LENGTH,
    MAX_UPLOAD_SIZE,
    TICKET_CONTACT_TYPES_MODEL,
    TICKET_TYPES_MODEL
} from '@/features/tickets/utils.ts'
import {Radio} from '@components/commons/radio/Radio.tsx'
import {InputHelpText} from '@components/ui/input-help-text/InputHelpText.tsx'
import {InputText} from '@components/commons/input-text/InputText.tsx'
import {Checkbox} from '@components/commons/checkbox/ChekBox.tsx'
import {Controller, SubmitHandler, useForm, useWatch} from 'react-hook-form'
import {InputFile} from '@components/commons/input-file/InputFile.tsx'
import {FileCheck02Icon, UploadCloud01Icon} from '@components/ui/icon/Icon.tsx'
import {Trans, useTranslation} from 'react-i18next'
import {TextArea} from '@components/commons/textarea/TextArea.tsx'
import {useCreateTicket} from '@/features/tickets/services/query/useCreateTicket.ts'
import toast from 'react-hot-toast'
import {useEditTicketForm} from '@/features/tickets/services/query/useEditTicketForm.ts'
import {TicketFormSchema} from '@/features/tickets/components/ticket-form-aside-content/TicketFormModel.ts'
import {zodResolver} from '@hookform/resolvers/zod'
import {useTheme} from 'styled-components'
import {Scrollbar} from '@components/ui/scrollbar/Scrollbar.tsx'
import {ScrollArea} from '@components/ui/scroll-area/ScrollArea.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {useTicketAsideStore} from '@/features/tickets/store/useTicketAside.ts'
import {FC} from 'react'
import {useRemoveTicketDelegation} from '@/features/tickets/services/query/useRemoveTicketDelegation.ts'

interface TicketFormProps {
    defaultValues?: Partial<TicketFormSchema>
}

export const TicketForm: FC<TicketFormProps> = ({defaultValues}) => {
    const {t} = useTranslation()
    const setTicketId = useTicketAsideStore(state => state.setTicketId)
    const onCloseAside = useTicketAsideStore(state => state.onCloseAside)
    const ticketId = useTicketAsideStore(state => state.ticketId)
    const setView = useTicketAsideStore(state => state.setView)
    const createTicket = useCreateTicket({
        onSuccess: data => {
            toast.success(t('tickets:ticket_form:add_success'))
            setTicketId(data.id)
            setView('detail')
        }
    })
    const editTicket = useEditTicketForm(ticketId || undefined, {
        onSuccess: () => {
            toast.success(t('tickets:ticket_form:edit_success'))
            setView('detail')
        }
    })
    const removeTicketDelegation = useRemoveTicketDelegation(ticketId || undefined, {
        onSuccess: () => resetDelegatingField()
    })

    const form = useForm<TicketFormSchema>({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        resolver: zodResolver(TicketFormSchema),
        defaultValues: {
            isDelegating: false,
            ...defaultValues
        }
    })

    const onSubmit: SubmitHandler<TicketFormSchema> = async data => {
        if (ticketId) {
            if (!watchIsDelegation) {
                await removeTicketDelegation.mutateAsync()
            }
            editTicket.mutate(adaptTicketFromFormToEditMutation({data}))
        } else {
            createTicket.mutate(adaptTicketFromFormToCreateMutation({data}))
        }
    }

    const {palette} = useTheme()
    const watchIsDelegation = useWatch({control: form.control, name: 'isDelegating'})
    const delegationFile = useWatch({control: form.control, name: 'delegation'})
    const identityDocumentFile = useWatch({control: form.control, name: 'identityDocument'})
    const delegationUploaded = useWatch({control: form.control, name: 'delegationUploaded'})
    const identityDocumentUploaded = useWatch({control: form.control, name: 'identityDocumentUploaded'})
    const content = useWatch({control: form.control, name: 'content'})

    const resetDelegatingField = () => {
        form.setValue('delegation', undefined)
        form.setValue('delegationUploaded', undefined)
        form.setValue('identityDocumentUploaded', undefined)
        form.setValue('identityDocument', undefined)
        form.setValue('patientFirstName', '')
        form.setValue('patientLastName', '')
    }

    const onCLickCancel = () => {
        if (ticketId) {
            setView('detail')
        } else {
            onCloseAside()
        }
    }

    return (
        <>
            <ScrollArea scrollbar={<Scrollbar />}>
                <form id={'ticket_form'} onSubmit={form.handleSubmit(onSubmit)}>
                    <AsideSection direction={'column'} gap={6}>
                        <Flexbox fullWidth direction={'column'} gap={4}>
                            <StyledSectionTitle>{t('tickets:ticket_form:info')}</StyledSectionTitle>
                            <Flexbox fullWidth direction={'column'} gap={1.5}>
                                <Label>{`${t('tickets:ticket_form:contact_type_label')}*`}</Label>
                                <Flexbox fullWidth direction={'column'} gap={2}>
                                    {TICKET_CONTACT_TYPES_MODEL.map(type => (
                                        <Radio
                                            key={type.value}
                                            disabled={createTicket.isPending || editTicket.isPending}
                                            label={t(type.label)}
                                            value={type.value}
                                            {...form.register('contactType')}
                                        />
                                    ))}
                                </Flexbox>
                                {form.formState.errors.contactType?.message && (
                                    <InputHelpText error={t(form.formState.errors.contactType?.message || '')} />
                                )}
                            </Flexbox>
                            <Flexbox fullWidth gap={4}>
                                <InputText
                                    label={`${t('tickets:ticket_form:firstname')}*`}
                                    placeholder={t('tickets:ticket_form:firstname')}
                                    type={'text'}
                                    disabled={createTicket.isPending || editTicket.isPending}
                                    maxLength={64}
                                    errorMessage={t(form.formState.errors.senderFirstName?.message || '')}
                                    {...form.register('senderFirstName')}
                                />
                                <InputText
                                    label={`${t('tickets:ticket_form:lastname')}*`}
                                    placeholder={t('tickets:ticket_form:lastname')}
                                    type={'text'}
                                    disabled={createTicket.isPending || editTicket.isPending}
                                    maxLength={64}
                                    errorMessage={t(form.formState.errors.senderLastName?.message || '')}
                                    {...form.register('senderLastName')}
                                />
                            </Flexbox>
                            <InputText
                                label={`${t('tickets:ticket_form:email')}*`}
                                placeholder={t('tickets:ticket_form:email')}
                                type={'email'}
                                disabled={createTicket.isPending || editTicket.isPending}
                                maxLength={64}
                                errorMessage={t(form.formState.errors.email?.message || '')}
                                {...form.register('email')}
                            />
                            <InputText
                                label={`${t('tickets:ticket_form:phone')}*`}
                                placeholder={t('tickets:ticket_form:phone')}
                                type={'tel'}
                                disabled={createTicket.isPending || editTicket.isPending}
                                maxLength={15}
                                errorMessage={t(form.formState.errors.phoneNumber?.message || '')}
                                {...form.register('phoneNumber')}
                            />
                            <Checkbox
                                id={'isDelegating'}
                                label={t('tickets:ticket_form:delegation_label')}
                                disabled={createTicket.isPending || editTicket.isPending}
                                errorMessage={t(form.formState.errors.isDelegating?.message || '')}
                                helpText={t('tickets:ticket_form:delegation_help_text')}
                                {...form.register('isDelegating', {
                                    onChange: resetDelegatingField
                                })}
                            />
                        </Flexbox>
                        {watchIsDelegation && (
                            <Flexbox fullWidth direction={'column'} gap={4}>
                                <StyledSectionTitle>{t('tickets:ticket_form:delegation')}</StyledSectionTitle>
                                <Flexbox fullWidth gap={4}>
                                    <InputText
                                        label={`${t('tickets:ticket_form:firstname')}*`}
                                        placeholder={t('tickets:ticket_form:firstname')}
                                        type={'text'}
                                        disabled={createTicket.isPending || editTicket.isPending}
                                        maxLength={64}
                                        errorMessage={t(form.formState.errors.patientFirstName?.message || '')}
                                        {...form.register('patientFirstName')}
                                    />
                                    <InputText
                                        label={`${t('tickets:ticket_form:lastname')}*`}
                                        placeholder={t('tickets:ticket_form:lastname')}
                                        type={'text'}
                                        disabled={createTicket.isPending || editTicket.isPending}
                                        maxLength={64}
                                        errorMessage={t(form.formState.errors.patientLastName?.message || '')}
                                        {...form.register('patientLastName')}
                                    />
                                </Flexbox>

                                <Controller
                                    render={({field: {onChange, ref}, fieldState: {error}}) => (
                                        <InputFile
                                            ref={ref}
                                            acceptedFiles={ACCEPTED_FILE_TYPES}
                                            maxSizeInMB={MAX_UPLOAD_SIZE}
                                            disabled={createTicket.isPending || editTicket.isPending}
                                            errorMessage={t(error?.message || '')}
                                            label={`${t('tickets:ticket_form:signed_delegation_file')}*`}
                                            typeIcon={
                                                delegationFile || delegationUploaded ? (
                                                    <FileCheck02Icon color={palette.success[600]} />
                                                ) : (
                                                    <UploadCloud01Icon color={palette.neutral[600]} />
                                                )
                                            }
                                            onAcceptFiles={file => onChange(file)}
                                            noClick={!!delegationFile || !!delegationUploaded}
                                            centerSlot={
                                                delegationFile || delegationUploaded ? (
                                                    <InputFileValue justify="space-between" align="center">
                                                        {delegationFile?.name || delegationUploaded?.name}
                                                    </InputFileValue>
                                                ) : (
                                                    <InputFilePlaceholder direction="column">
                                                        <div>
                                                            <Trans
                                                                i18nKey={t(
                                                                    'tickets:ticket_form:input_file_placeholder'
                                                                )}
                                                                components={{span: <InputFileHighlightedPlaceholder />}}
                                                            />
                                                        </div>
                                                        {t('tickets:ticket_form:input_file_available')}
                                                    </InputFilePlaceholder>
                                                )
                                            }
                                            endSlot={
                                                (delegationFile || delegationUploaded) && (
                                                    <StyledRemoveFileButton
                                                        variant="ghost"
                                                        onClick={() => {
                                                            onChange(undefined)
                                                            form.setValue('delegationUploaded', undefined)
                                                        }}
                                                        disabled={createTicket.isPending || editTicket.isPending}
                                                    >
                                                        {t('commons:remove')}
                                                    </StyledRemoveFileButton>
                                                )
                                            }
                                        />
                                    )}
                                    control={form.control}
                                    name={'delegation'}
                                />

                                <Controller
                                    render={({field: {onChange, ref}}) => (
                                        <InputFile
                                            ref={ref}
                                            acceptedFiles={ACCEPTED_FILE_TYPES}
                                            maxSizeInMB={MAX_UPLOAD_SIZE}
                                            errorMessage={t(form.formState.errors.identityDocument?.message || '')}
                                            disabled={createTicket.isPending || editTicket.isPending}
                                            label={`${t('tickets:ticket_form:identity_document_file')}*`}
                                            typeIcon={
                                                identityDocumentFile || identityDocumentUploaded ? (
                                                    <FileCheck02Icon color={palette.success[600]} />
                                                ) : (
                                                    <UploadCloud01Icon color={palette.neutral[600]} />
                                                )
                                            }
                                            onAcceptFiles={file => onChange(file)}
                                            noClick={!!identityDocumentFile || !!identityDocumentUploaded}
                                            centerSlot={
                                                identityDocumentFile || identityDocumentUploaded ? (
                                                    <InputFileValue justify="space-between" align="center">
                                                        {identityDocumentFile?.name || identityDocumentUploaded?.name}
                                                    </InputFileValue>
                                                ) : (
                                                    <InputFilePlaceholder direction="column">
                                                        <div>
                                                            <Trans
                                                                i18nKey={t(
                                                                    'tickets:ticket_form:input_file_placeholder'
                                                                )}
                                                                components={{span: <InputFileHighlightedPlaceholder />}}
                                                            />
                                                        </div>
                                                        {t('tickets:ticket_form:input_file_available')}
                                                    </InputFilePlaceholder>
                                                )
                                            }
                                            endSlot={
                                                (identityDocumentFile || identityDocumentUploaded) && (
                                                    <StyledRemoveFileButton
                                                        variant="ghost"
                                                        disabled={createTicket.isPending || editTicket.isPending}
                                                        onClick={() => {
                                                            onChange(undefined)
                                                            form.setValue('identityDocumentUploaded', undefined)
                                                        }}
                                                    >
                                                        {t('commons:remove')}
                                                    </StyledRemoveFileButton>
                                                )
                                            }
                                        />
                                    )}
                                    control={form.control}
                                    name={'identityDocument'}
                                />
                            </Flexbox>
                        )}
                        <Flexbox fullWidth gap={4} direction={'column'}>
                            <StyledSectionTitle>{t('tickets:ticket_form:report')}</StyledSectionTitle>
                            <Flexbox direction={'column'} gap={1.5} fullWidth>
                                <StyledOpenTicketModalRadioGrid>
                                    {TICKET_TYPES_MODEL.map(type => (
                                        <Radio
                                            key={type.value}
                                            label={t(type.label)}
                                            disabled={createTicket.isPending || editTicket.isPending}
                                            value={type.value}
                                            {...form.register(t('type'))}
                                        />
                                    ))}
                                </StyledOpenTicketModalRadioGrid>
                                {form.formState.errors.type?.message && (
                                    <InputHelpText error={t(form.formState.errors.type?.message || '')} />
                                )}
                            </Flexbox>

                            <TextArea
                                label={`${t('tickets:ticket_form:report')}*`}
                                rows={5}
                                cols={33}
                                maxLength={MAX_CONTENT_LENGTH}
                                disabled={createTicket.isPending || editTicket.isPending}
                                errorMessage={t(form.formState.errors.content?.message || '')}
                                placeholder={t('tickets:ticket_form:content_placeholder')}
                                helpText={`${content ? content.length : 0}/${MAX_CONTENT_LENGTH}`}
                                {...form.register('content')}
                            />
                        </Flexbox>
                    </AsideSection>
                </form>
            </ScrollArea>
            <AsideFooter fullWidth justify={'space-between'}>
                <Button
                    disabled={createTicket.isPending || editTicket.isPending}
                    variant={'secondary'}
                    onClick={onCLickCancel}
                >
                    {t('commons:cancel')}
                </Button>
                <Button
                    disabled={createTicket.isPending || editTicket.isPending || !form.formState.isDirty}
                    form={'ticket_form'}
                >
                    {ticketId ? t('commons:edit') : t('commons:add')}
                    {createTicket.isPending || (editTicket.isPending && <Spinner size={20} />)}
                </Button>
            </AsideFooter>
        </>
    )
}
