import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledUserFullName = styled.p(
    ({theme: {typography, palette}}) => css`
        ${typography.textXl};
        font-weight: 500;
        color: ${palette.neutral[900]};
    `
)

export const StyledReportedLabel = styled.p(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm};
        color: ${palette.neutral[600]};
        & b {
            font-weight: 500;
        }
    `
)

export const StyledCloseTicketInfoContainer = styled(Flexbox)(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm};
        & p {
            color: ${palette.success[600]};
            font-weight: 600;
        }
        & span {
            color: ${palette.neutral[600]};
        }
    `
)

export const StyledTicketAsideGrid = styled.div(
    () => css`
        height: 100%;
        display: grid;
        grid-template-rows: auto minmax(0, 1fr);
    `
)
