import {ComboboxItem, ComboboxProvider, SelectProvider} from '@ariakit/react'
import {
    SelectComboboxInput,
    SelectComboboxList,
    SelectComboboxPopover,
    SelectItem,
    SelectTrigger,
    SelectVirtualList
} from '@components/ui/select-atoms/SelectAtoms.tsx'
import {FC} from 'react'
import {InputHelpText} from '@components/ui/input-help-text/InputHelpText.tsx'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useTranslation} from 'react-i18next'
import {useGetDepartments} from '@/features/tickets/services/query/useGetDepartments.ts'
import {Label} from '@components/ui/label/Label.tsx'
import {Ticket} from '@/features/tickets/types.ts'

interface RoleFilterSelectProps {
    value?: Ticket['departments'][number]
    onChange: (value: string) => void
    disabled?: boolean
    onClear?: () => void
}
export const DepartmentSelect: FC<RoleFilterSelectProps> = ({value, onChange, disabled, onClear}) => {
    const {t} = useTranslation()
    const query = useGetDepartments()
    return (
        <ComboboxProvider setValue={query.onSearch} defaultValue={query.searchValue} resetValueOnHide>
            <SelectProvider setValue={onChange} value={value?.id.toString() || ''}>
                <Flexbox direction={'column'} fullWidth gap={1.5}>
                    <Label>{t('tickets:aside:contact_type_select:label')}</Label>
                    <SelectTrigger
                        disabled={disabled}
                        variant={'ghost'}
                        fullWidth
                        displayValue={() => value?.name}
                        placeholder={t('tickets:aside:contact_type_select:label')}
                        onClear={value?.name ? onClear : undefined}
                    />
                </Flexbox>
                <SelectComboboxPopover sameWidth>
                    <SelectComboboxInput />
                    <SelectComboboxList>
                        {query.isLoading ? (
                            <Flexbox direction="column" gap={1}>
                                {Array.from({length: 3}, (_, index) => (
                                    <Skeleton key={index} height={32} />
                                ))}
                            </Flexbox>
                        ) : query.isError ? (
                            <InputHelpText error={t('errors:default')} />
                        ) : query.remappedData.length == 0 ? (
                            <InputHelpText helpText={t('commons:no_results')} />
                        ) : (
                            <SelectVirtualList
                                data={query.remappedData}
                                totalCount={query.remappedData.length}
                                overscan={100}
                                endReached={query.fetchNextPage}
                                itemContent={(_, option) => (
                                    <SelectItem render={<ComboboxItem />} value={option.id.toString()} key={option.id}>
                                        {option.name}
                                    </SelectItem>
                                )}
                            />
                        )}
                    </SelectComboboxList>
                </SelectComboboxPopover>
            </SelectProvider>
        </ComboboxProvider>
    )
}
