import {AlertTriangleIcon, XCloseIcon} from '@components/ui/icon/Icon.tsx'
import {AsideContentWrapper, AsideHeader} from '@/features/tickets/components/atoms/TicketsAtoms.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from 'react-i18next'
import {TicketForm} from '@/features/tickets/components/ticket-form-aside-content/TicketForm.tsx'
import {useGetTicketsDetails} from '@/features/tickets/services/query/useGetTicketsDetails.ts'
import {useTicketAsideStore} from '@/features/tickets/store/useTicketAside.ts'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'

export const TicketFormContent = () => {
    const {t} = useTranslation()
    const ticketId = useTicketAsideStore(state => state.ticketId)
    const onCloseAside = useTicketAsideStore(state => state.onCloseAside)

    const ticketQuery = useGetTicketsDetails(ticketId || undefined)

    return (
        <AsideContentWrapper>
            <AsideHeader fullWidth justify={'space-between'} align={'center'}>
                <h3>{ticketId ? t('tickets:ticket_form:edit_ticket') : t('tickets:ticket_form:add_ticket')}</h3>
                <Button variant={'ghost'} onClick={onCloseAside}>
                    <XCloseIcon />
                </Button>
            </AsideHeader>
            {ticketId ? (
                ticketQuery.isPending ? (
                    <Spinner />
                ) : ticketQuery.isError ? (
                    <ErrorBox icon={<AlertTriangleIcon />} title={'Tet'} />
                ) : (
                    ticketQuery.data && (
                        <TicketForm
                            defaultValues={{
                                contactType: ticketQuery.data.contactType,
                                senderFirstName: ticketQuery.data.senderFirstName,
                                senderLastName: ticketQuery.data.senderLastName,
                                isDelegating: !!ticketQuery.data.patientFirstName,
                                patientFirstName: ticketQuery.data.patientFirstName || undefined,
                                patientLastName: ticketQuery.data.patientLastName || undefined,
                                email: ticketQuery.data.email,
                                phoneNumber: ticketQuery.data.phoneNumber,
                                content: ticketQuery.data.content,
                                type: ticketQuery.data.type,
                                identityDocument: undefined,
                                delegation: undefined,
                                identityDocumentUploaded:
                                    ticketQuery.data.attachments.find(
                                        attachment => attachment.type == 'identity_document'
                                    ) || undefined,
                                delegationUploaded:
                                    ticketQuery.data.attachments.find(attachment => attachment.type == 'delegation') ||
                                    undefined
                            }}
                        />
                    )
                )
            ) : (
                <TicketForm />
            )}
        </AsideContentWrapper>
    )
}
