import {TicketDetailAsideContent} from '@/features/tickets/components/ticket-detail-aside-content/TicketDetailAsideContent.tsx'
import {SlidingModal} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {useTicketAsideStore} from '@/features/tickets/store/useTicketAside.ts'
import {TicketFormContent} from '@/features/tickets/components/ticket-form-aside-content/TicketFormContent.tsx'
import {useEffect} from 'react'
import {TicketURLParams} from '@/features/tickets/types.ts'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'

export const TicketAside = () => {
    const slidingModalState = useTicketAsideStore(state => state.slidingModalState)
    const view = useTicketAsideStore(state => state.view)
    const multiParams = [] as const satisfies ReadonlyArray<keyof TicketURLParams>
    const {searchParams, setSearchParams} = useParsedSearchParams(multiParams, TicketURLParams)
    useEffect(() => {
        return () => {
            if (searchParams.ticketId) {
                setSearchParams({ticketId: undefined})
            }
        }
    }, [])
    return (
        <SlidingModal slidingModalState={slidingModalState} width={'490px'}>
            {view == 'detail' ? <TicketDetailAsideContent /> : <TicketFormContent />}
        </SlidingModal>
    )
}
