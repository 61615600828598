import axios from '../../../axiosInstance.ts'
import {
    CreateTicketPayload,
    HttpEditFormTicketParams,
    HttpEditTicketParams,
    HttpGetDepartmentsParams,
    HttpGetTicketDetailsParams,
    HttpGetTicketsParams
} from '@/features/tickets/types.ts'
import {HttpExportParams} from '@/types/commons.ts'

/**
 * httpGetTickets
 * Here we will fetch all the tickets data for the dash table
 */

export const httpGetTickets = async (params: HttpGetTicketsParams) => {
    return await axios.get(`/tickets`, {params})
}

/**
 * httpExportTickets
 * Here we will export tickets
 */

export const httpExportTickets = async (params: HttpExportParams) => {
    return await axios.get(`/tickets/export`, {params})
}

/**
 * httpGetTicketDetails
 * Here we will fetch detail ticket data
 */

export const httpGetTicketDetails = async (params: HttpGetTicketDetailsParams) => {
    return await axios.get(`/tickets/${params.ticketId}`, {params})
}

/**
 * httpEditFormTicket
 * Here we will update ticket data with the form
 */

export const httpEditFormTicket = async (params: HttpEditFormTicketParams) => {
    return await axios.patch(`/tickets/${params.ticketId}`, params.data, {
        headers: {'Content-Type': 'multipart/form-data'}
    })
}

/**
 * httpEditFormTicket
 * Here we will update ticket data with the form
 */

export const httpEditTicket = async (params: HttpEditTicketParams) => {
    return await axios.patch(`/tickets/${params.ticketId}`, params.data)
}

/**
 * httpRemoveTicketDelegation
 * Here we will remove ticket delegation
 */

export const httpRemoveTicketDelegation = async (params: {ticketId: number}) => {
    return await axios.delete(`/tickets/${params.ticketId}/remove-delegation`)
}

/**
 * httpGetDepartments
 * Here we will fetch department data
 */

export const httpGetDepartments = async (params: HttpGetDepartmentsParams) => {
    return await axios.get(`/departments`, {params})
}

/**
 * httpGetAssignable
 * Here we will fetch department data
 */
export const httpGetAssignable = async (params?: {searchString?: string}) => {
    return await axios.get(`/users/assignable`, {params})
}

/**
 * httpGetDepartments
 * Here we will fetch department data
 */

export const httpCreateTicket = (payload: CreateTicketPayload) =>
    axios.post(`/tickets`, payload, {headers: {'Content-Type': 'multipart/form-data'}})

/**
 * httpDeleteTicket
 * Here we will fetch department data
 */

export const httpDeleteTicket = (params: {ticketId: number}) => axios.delete(`/tickets/${params.ticketId}`)

export const httpRemoveDepartments = (params: {ticketId: number}, payload: {departmentsIds: number[]}) =>
    axios.patch(`/tickets/${params.ticketId}/departments/remove`, payload)
