import {z} from 'zod'
import {ACCEPTED_FILE_TYPES, MAX_CONTENT_LENGTH, MAX_UPLOAD_SIZE} from '@/features/tickets/utils'
import {sizeInMB} from '@/features/tickets/utils.ts'
import {Attachment, ContactType} from '@/features/tickets/types.ts'

export const TicketFormSchema = z
    .object({
        contactType: z.enum(ContactType.options, {invalid_type_error: 'errors:required'}),
        senderFirstName: z.string().min(1, {message: 'errors:required'}),
        senderLastName: z.string().min(1, {message: 'errors:required'}),
        patientFirstName: z.string().optional(),
        patientLastName: z.string().optional(),
        email: z.string().email({message: 'errors:email'}),
        phoneNumber: z
            .string()
            .min(3, {message: 'tickets:ticket_form:errors:phoneNumber'})
            .max(15, {message: 'tickets:ticket_form:errors:phoneNumber'})
            .refine(value => value.match('[0-9]+'), {message: 'tickets:ticket_form:errors:phoneNumber'}),
        isDelegating: z.boolean(),
        content: z
            .string()
            .min(1, {message: 'errors:required'})
            .trim()
            .max(MAX_CONTENT_LENGTH, {message: 'tickets:ticket_form:errors:max_content'}),
        type: z
            .enum(['praise', 'complaint', 'help', 'refund', 'suggestion', 'info'], {
                invalid_type_error: 'errors:required'
            })
            .default('info'),
        identityDocument: z
            .instanceof(File)
            .refine(file => {
                return !file || sizeInMB(file.size) <= MAX_UPLOAD_SIZE
            }, `The maximum image size is ${MAX_UPLOAD_SIZE}MB`)
            .refine(file => {
                return file?.type && Object.keys(ACCEPTED_FILE_TYPES).includes(file?.type)
            }, 'File type is not supported')
            .optional(),
        delegation: z
            .instanceof(File)
            .refine(file => {
                return !file || sizeInMB(file.size) <= MAX_UPLOAD_SIZE
            }, `The maximum image size is ${MAX_UPLOAD_SIZE}MB`)
            .refine(file => {
                return file?.type && Object.keys(ACCEPTED_FILE_TYPES).includes(file?.type)
            }, 'File type is not supported')
            .optional(),
        delegationUploaded: Attachment.optional(),
        identityDocumentUploaded: Attachment.optional()
    })
    .superRefine((values, context) => {
        if (values.isDelegating && !values.patientFirstName) {
            context.addIssue({
                code: 'custom',
                path: ['patientFirstName'],
                message: 'errors:required'
            })
        }
        if (values.isDelegating && !values.patientLastName) {
            context.addIssue({
                code: 'custom',
                path: ['patientLastName'],
                message: 'errors:required'
            })
        }
        if (values.isDelegating && !values.delegation && !values.delegationUploaded) {
            context.addIssue({
                code: 'custom',
                path: ['delegation'],
                message: 'errors:required'
            })
        }
        if (values.isDelegating && !values.identityDocument && !values.identityDocumentUploaded) {
            context.addIssue({
                code: 'custom',
                path: ['identityDocument'],
                message: 'errors:required'
            })
        }
    })
export type TicketFormSchema = z.infer<typeof TicketFormSchema>
