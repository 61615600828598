import {FC} from 'react'
import {StyledDateFilterWrapper} from '@components/commons/date-filter-select/style.ts'
import {CalendarIcon} from '@components/ui/icon/Icon.tsx'
import {SelectProvider, SelectGroup} from '@ariakit/react'
import {SelectItem, SelectList, SelectPopover, SelectTrigger} from '@components/ui/select-atoms/SelectAtoms.tsx'
import {selectDateOption} from '@components/commons/date-filter-select/utils.ts'
import {Divider} from '@components/ui/divider/Divider.tsx'

interface DateFilterSelectProps {
    onChange: (newValue: string) => void
    defaultValue?: string
    value?: string
    haveAnyDate?: boolean
}
export const DateFilterSelect: FC<DateFilterSelectProps> = ({onChange, defaultValue, haveAnyDate}) => {
    const anyDateItem = selectDateOption.find(option => option.value == 'any_date')
    const filteredSelectDateOptions = selectDateOption.filter(option => option.value != 'any_date')
    return (
        <StyledDateFilterWrapper>
            <SelectProvider setValue={onChange} defaultValue={defaultValue}>
                <SelectTrigger
                    size={'sm'}
                    variant={'ghost'}
                    fullWidth
                    displayValue={value => selectDateOption.find(option => option.value == value)?.label}
                    typeIcon={<CalendarIcon />}
                />
                <SelectPopover sameWidth>
                    <SelectList>
                        {haveAnyDate && (
                            <>
                                {anyDateItem && (
                                    <SelectGroup>
                                        <SelectItem value={anyDateItem.value} key={anyDateItem.value}>
                                            {anyDateItem.label}
                                        </SelectItem>
                                    </SelectGroup>
                                )}
                                <Divider startSpacing={1} endSpacing={1} direction={'horizontal'} />
                            </>
                        )}
                        <SelectGroup>
                            {filteredSelectDateOptions.map(option => (
                                <SelectItem value={option.value} key={option.value}>
                                    {option.label}
                                </SelectItem>
                            ))}
                        </SelectGroup>
                    </SelectList>
                </SelectPopover>
            </SelectProvider>
        </StyledDateFilterWrapper>
    )
}
