import {Dispatch, FC, SetStateAction} from 'react'
import {Conversation} from '@/features/conversation/types.ts'
import {
    StyledConversationGrid,
    StyledConversationListHeader,
    StyledConversationListWrapper,
    StyledConversationSelectorContainer,
    StyledMessagesListHeader,
    StyledMessagesListWrapper,
    StyledTickedIdButton
} from '@/features/conversation/components/conversation-slector/style.ts'
import {useTranslation} from 'react-i18next'
import {ConversationList} from '@/features/conversation/components/conversation-list/ConversationList.tsx'
import {MessagesList} from '@/features/conversation/components/messages-list/MessagesList.tsx'
import dayjs from 'dayjs'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {FaceSadIcon, FaceSmileIcon} from '@components/ui/icon/Icon.tsx'
import {useAuthStore} from '@/features/auth/store/store.ts'
import {containsAtLeastOne} from '@utilities/helpers.ts'
import {getPermitName} from '@/features/users/utils.ts'
import {useTicketAsideStore} from '@/features/tickets/store/useTicketAside.ts'
interface ConversationSelectorProps {
    conversations: Conversation[]
    totalConversation?: number
    isChangingPage: boolean
    isError: boolean
    isLoading: boolean
    onChangePage?: () => void
    currentConversationIndex: number
    setCurrentConversationIndex: Dispatch<SetStateAction<number>>
}
export const ConversationSelector: FC<ConversationSelectorProps> = ({
    totalConversation,
    conversations,
    isLoading,
    isChangingPage,
    isError,
    onChangePage,
    currentConversationIndex,
    setCurrentConversationIndex
}) => {
    const setTicketId = useTicketAsideStore(state => state.setTicketId)
    const setSlidingModalState = useTicketAsideStore(state => state.setSlidingModalState)
    const setView = useTicketAsideStore(state => state.setView)
    const user = useAuthStore(state => state.user)
    const {t} = useTranslation()
    return (
        <StyledConversationSelectorContainer>
            {!isLoading && (
                <>
                    <StyledConversationGrid>
                        <StyledConversationListHeader>
                            <h4>
                                {totalConversation && totalConversation} {`${t('conversations:title')}`}
                            </h4>
                        </StyledConversationListHeader>
                        <StyledConversationListWrapper>
                            <ConversationList
                                isChangingPage={isChangingPage}
                                isLoading={isLoading}
                                conversationActiveIndex={currentConversationIndex}
                                isError={isError}
                                onChangePage={onChangePage}
                                conversations={conversations}
                                setCurrentConversationIndex={setCurrentConversationIndex}
                            />
                        </StyledConversationListWrapper>
                    </StyledConversationGrid>
                    <StyledConversationGrid>
                        <StyledMessagesListHeader justify={'space-between'}>
                            <Flexbox gap={4} align={'center'}>
                                <h4>{t('conversations:conversation')}</h4>
                                {conversations[currentConversationIndex]?.isSatisfied != null &&
                                    (conversations[currentConversationIndex]?.isSatisfied ? (
                                        <FaceSmileIcon size={18} />
                                    ) : (
                                        <FaceSadIcon size={18} />
                                    ))}
                                {conversations[currentConversationIndex]?.ticketId &&
                                    user &&
                                    containsAtLeastOne(['ticket'], getPermitName(user.permissions)) && (
                                        <StyledTickedIdButton
                                            size={'sm'}
                                            variant={'tertiary'}
                                            onClick={() => {
                                                setTicketId(conversations[currentConversationIndex]?.ticketId)
                                                setView('detail')
                                                setSlidingModalState('open')
                                            }}
                                        >
                                            {`#${conversations[currentConversationIndex]?.ticketId}`}
                                        </StyledTickedIdButton>
                                    )}
                            </Flexbox>
                            <span>
                                {conversations[currentConversationIndex]?.createdAt &&
                                    dayjs(conversations[currentConversationIndex]?.createdAt).format('ddd DD MMM')}
                            </span>
                        </StyledMessagesListHeader>
                        <StyledMessagesListWrapper>
                            {conversations[currentConversationIndex]?.id && (
                                <MessagesList conversationId={conversations[currentConversationIndex]?.id} />
                            )}
                        </StyledMessagesListWrapper>
                    </StyledConversationGrid>
                </>
            )}
        </StyledConversationSelectorContainer>
    )
}
