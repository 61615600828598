import {FC} from 'react'
import {EmptyPlaceholder} from '@components/commons/table/empty-placeholder/EmptyPlaceholder.tsx'
import {ErrorMessage} from '@components/commons/table/error-message/ErrorMessage.tsx'
import {Message} from '@components/ui/message/Message.tsx'
import {useGetMessages} from '@/features/message/services/query/useGetMessages.ts'
import {MessageSkeleton} from '@/features/conversation/components/messages-list/MessageSkeleton.tsx'
import {
    StyledMessageContainer,
    StyledMessageVirtualList
} from '@/features/conversation/components/messages-list/style.ts'

interface MessagesListProps {
    conversationId: number
}

export const MessagesList: FC<MessagesListProps> = ({conversationId}) => {
    const {
        remappedData: messages,
        isError,
        isLoading,
        isFetchingNextPage,
        fetchNextPage
    } = useGetMessages({responseType: 'regular', limit: 30, conversationId: conversationId})

    const filteredMessages = messages.filter(msg => msg.message.trim() !== '')

    return (
        <StyledMessageVirtualList
            data={filteredMessages}
            overscan={100}
            endReached={isError ? undefined : fetchNextPage}
            itemContent={(_itemIndex, item) => {
                return (
                    <StyledMessageContainer>
                        <Message message={item} firstname={null} lastname={null} />
                    </StyledMessageContainer>
                )
            }}
            components={{
                EmptyPlaceholder: () => {
                    if (isLoading) {
                        return <MessageSkeleton />
                    }
                    if (isError) {
                        return null
                    }

                    return <EmptyPlaceholder />
                },
                Footer: () => {
                    if (isLoading) {
                        return null
                    }
                    if (isError) {
                        return <ErrorMessage />
                    }
                    if (isFetchingNextPage) {
                        return <MessageSkeleton />
                    }

                    return null
                }
            }}
        />
    )
}
