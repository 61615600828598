import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {ResponseType} from '@/types/commons'
import {useInputSearch} from '@/hooks/useInputSearch'
import {useEffect} from 'react'
import {useGetTickets} from '@/features/tickets/services/query/useGetTickets.ts'
import {TicketsSearchParams, TicketURLParams} from '@/features/tickets/types.ts'

export const useTicketsTable = () => {
    const multiParams = [] as const satisfies ReadonlyArray<keyof TicketURLParams>
    const {searchParams, setSearchParams} = useParsedSearchParams(multiParams, TicketURLParams)
    const {searchValue, searchError, onSearch, onResetSearch} = useInputSearch(searchParams.searchString)
    const ticketsQuery = useGetTickets({
        ...searchParams,
        limit: 20,
        responseType: ResponseType.enum.regular
    })

    useEffect(() => {
        if (searchValue) {
            setSearchParams({searchString: searchValue})
        } else {
            setSearchParams({searchString: undefined})
        }
    }, [searchValue])

    const toggleCompletedHandler = (completed: boolean) => {
        setSearchParams({isCompleted: completed ? 'true' : 'false'})
    }

    const sort = (sorter: {
        orderBy: TicketsSearchParams['orderBy']
        orderDirection: TicketsSearchParams['orderDirection']
    }) => {
        if (sorter.orderDirection) {
            setSearchParams({orderBy: sorter.orderBy, orderDirection: sorter.orderDirection})
        } else {
            setSearchParams({orderBy: undefined, orderDirection: undefined})
        }
    }
    return {
        ...ticketsQuery,
        onSearch,
        onResetSearch,
        searchError,
        searchValue,
        setSearchParams,
        searchParams,
        toggleCompletedHandler,
        sorter: {orderBy: searchParams.orderBy, orderDirection: searchParams.orderDirection, onSort: sort},
        filterValue: Object.values(searchParams)
            .filter(param => param !== 'searchString')
            .some(value => value !== null && value !== undefined)
    }
}
