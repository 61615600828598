import styled, {css} from 'styled-components'
import {User} from '@/features/users/types.ts'
import {Button} from '@components/ui/button/Button.tsx'

export const HeaderWrapper = styled.div`
    ${({theme: {palette}}) => css`
        box-sizing: border-box;
        background: ${palette.neutral.white};
        border-bottom: 1px solid ${palette.neutral['200']};
        height: 65px;
    `}
`

export const HeaderGrid = styled.div<{$user?: User | null}>`
    ${({$user, theme: {mediaQueries, spacing}}) => css`
        display: grid;
        align-items: center;
        grid-template-columns: ${$user ? 'auto 1fr auto auto' : 'auto 1fr auto'};
        height: 100%;
        gap: 32px;
        padding: 0 ${spacing * 4}px;
        ${mediaQueries.m} {
            grid-template-columns: auto 1fr auto;
        }
    `}
`

export const MenuItems = styled.div`
    ${() => css`
        display: flex;
        align-items: center;
    `}
`

export const DropdownUserInfosItems = styled.div`
    ${({theme: {spacing, palette, typography}}) => css`
        display: grid;
        align-items: center;
        gap: ${spacing}px;
        & span {
            ${typography.textSm};
            font-weight: 600;
            color: ${palette.neutral['700']};
        }
        & small {
            ${typography.textSm};
            font-weight: 400;
            color: ${palette.neutral['600']};
        }
        & p {
            ${typography.textXs};
            color: ${palette.neutral['400']};
        }
    `}
`

export const StyledAiAssistanceButton = styled(Button)`
    text-decoration: none;
`
