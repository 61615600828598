import {CSSProperties, forwardRef, InputHTMLAttributes, ReactNode} from 'react'
import {DefaultNamespace} from 'i18next'
import {
    StyledInputWrapper,
    StyledDropzone,
    StyledInputFile,
    StyledLabelContainer
} from '@components/commons/input-file/style.ts'
import {Accept, useDropzone} from 'react-dropzone'
import {megabytesToBytes} from '@utilities/helpers.ts'
import {Label} from '@components/ui/label/Label.tsx'
import {InputStatusIcon} from '@components/ui/input-status-icon/InputStatusIcon.tsx'
import {InputHelpText} from '@components/ui/input-help-text/InputHelpText.tsx'

export interface InputFileProps extends InputHTMLAttributes<HTMLInputElement> {
    acceptedFiles: Accept
    className?: string
    label?: string | DefaultNamespace
    maxFiles?: number
    maxSizeInMB: number
    name?: string
    onAcceptFiles?: (file: File) => void
    placeholder?: string
    typeIcon?: ReactNode
    touched?: boolean
    centerSlot?: ReactNode
    endSlot?: ReactNode
    labelEndSlot?: ReactNode
    noClick?: boolean
    disabled?: boolean
    /**
     * how to use errorMessage with translations
     *  errorMessage={t(errors.text?.message || '')} this avoid undefined value problems
     */
    errorMessage?: string | DefaultNamespace
    helpText?: string | DefaultNamespace
    inputSize?: 'md'
    width?: CSSProperties['width']
}

export const InputFile = forwardRef<HTMLInputElement, InputFileProps>(
    (
        {
            acceptedFiles,
            className,
            errorMessage,
            helpText,
            inputSize = 'md',
            label,
            maxFiles,
            maxSizeInMB,
            name,
            onAcceptFiles,
            touched = false,
            typeIcon,
            width = '100%',
            centerSlot,
            endSlot,
            labelEndSlot,
            disabled,
            noClick = false
        },
        ref
    ) => {
        const dropzoneState = useDropzone({
            accept: acceptedFiles,
            noClick,
            disabled: disabled,
            onDrop: acceptedFiles => {
                const [file] = acceptedFiles
                if (file) {
                    onAcceptFiles?.(file)
                }
            },
            maxFiles,
            maxSize: megabytesToBytes(maxSizeInMB)
        })

        return (
            <StyledInputFile className={className} $width={width} direction="column" gap={1.5}>
                <StyledLabelContainer justify="space-between">
                    {label && <Label htmlFor={name}>{label}</Label>}
                    {labelEndSlot}
                </StyledLabelContainer>
                <StyledDropzone ref={ref} state={dropzoneState} width="100%">
                    <StyledInputWrapper hasError={!!errorMessage} size={inputSize}>
                        {typeIcon}

                        {/* Removed: <p className="file-name">{dropzoneState.acceptedFiles[0]?.name ?? placeholder}</p>
                            Replaced by: centerSlot
                        */}
                        {centerSlot}

                        {/* Removed: <Button variant="tertiary">{t('commons:change')}</Button>
                            Replaced by: endSlot
                        */}
                        {endSlot}
                        <InputStatusIcon touched={touched} hasError={!!errorMessage} />
                    </StyledInputWrapper>
                </StyledDropzone>
                <InputHelpText error={errorMessage} helpText={helpText} />
            </StyledInputFile>
        )
    }
)

InputFile.displayName = 'InputFile'
