import {parseAxiosPromise} from '@/axiosInstance'
import {infiniteQueryFetchNextPage, QUERY_KEYS} from '@/queryClient'
import {useInfiniteQuery, UseInfiniteQueryOptions} from '@tanstack/react-query'
import {captureException} from '@sentry/react'
import {useInputSearch} from '@/hooks/useInputSearch'
import {z} from 'zod'
import {PaginationResponse} from '@/types/commons.ts'
import {Departments} from '@/features/tickets/types.ts'
import {httpGetDepartments} from '@/features/tickets/services/tickets.http.ts'

export const ResponseShape = z.object({
    data: z.array(Departments),
    meta: PaginationResponse
})
type ResponseShape = z.infer<typeof ResponseShape>

export const useGetDepartments = (options?: Pick<UseInfiniteQueryOptions<ResponseShape>, 'staleTime'>) => {
    const {searchValue, onSearch, onResetSearch} = useInputSearch()

    const query = useInfiniteQuery({
        queryKey: [QUERY_KEYS.DEPARTMENTS, searchValue],
        queryFn: ({pageParam = 1}) =>
            parseAxiosPromise({
                axiosPromise: httpGetDepartments({
                    page: pageParam,
                    limit: 50,
                    searchString: searchValue
                }),
                responseShape: ResponseShape,
                onZodError: captureException
            }),
        ...options,
        initialPageParam: 1,
        getNextPageParam: ({meta}) => (meta.current_page < meta.last_page ? meta.current_page + 1 : null)
    })

    return {
        ...query,
        fetchNextPage: () => infiniteQueryFetchNextPage(query),
        remappedData: query.data?.pages[0]?.data?.length ? query.data.pages.flatMap(page => page.data) : [],
        searchValue,
        onResetSearch,
        onSearch
    }
}
