import {z} from 'zod'
import {PaginationParams, PaginationResponse, ResponseType} from '@/types/commons.ts'

export const Permission = z.object({
    id: z.number(),
    name: z.enum(['conversation', 'ticket', 'dashboard', 'user'])
})
export type Permission = z.infer<typeof Permission>
export const User = z.object({
    id: z.number(),
    type: z.string(),
    email: z.string().email(),
    firstName: z.string(),
    lastName: z.string(),
    isActive: z.boolean(),
    status: z.enum(['active', 'invited', 'inactive']).optional(), //TODO remove '.optional()'
    createdAt: z.string().optional(),
    permissions: z.array(Permission),
    isAssignable: z.boolean()
})
export type User = z.infer<typeof User>
export const HttpGetUsersResponse = z.object({
    data: z.array(User),
    meta: PaginationResponse
})
export type HttpGetUsersResponse = z.infer<typeof HttpGetUsersResponse>

export const HttpGetUsersSearchParams = z.object({
    searchString: z.string().optional()
})
export type HttpGetUsersSearchParams = z.infer<typeof HttpGetUsersSearchParams>
export const HttpGetUsersParams = z.object({
    ...HttpGetUsersSearchParams.shape,
    ...PaginationParams.shape,
    responseType: ResponseType
})
export type HttpGetUsersParams = z.infer<typeof HttpGetUsersParams>

export const HttpCreateUserBody = z.object({
    email: z.string().email(),
    firstName: z.string(),
    userType: z.enum(['back_office', 'admin']).optional(), //TODO removed
    lastName: z.string(),
    permissionsIds: z.array(z.number()),
    isAssignable: z.boolean()
})
export type HttpCreateUserBody = z.infer<typeof HttpCreateUserBody>

export const HttpUpdateUserBody = z.object({
    data: HttpCreateUserBody,
    userId: z.number()
})
export type HttpUpdateUserBody = z.infer<typeof HttpUpdateUserBody>

export const HttpGetPermissionsResponse = z.object({
    data: z.array(Permission),
    meta: PaginationResponse
})
export type HttpGetPermissionsResponse = z.infer<typeof HttpGetPermissionsResponse>
