import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/en'
import 'dayjs/locale/it'
import i18next from 'i18next'

dayjs.extend(localizedFormat)

export const formatLocaleDate = (date: string | Date, formatType = 'YYYY-MM-DD') =>
    dayjs(date).locale(i18next.language).format(formatType)
export const getTimezone = (): string => {
    dayjs.extend(utc)
    dayjs.extend(timezone)
    return dayjs.tz.guess()
}

export default dayjs
