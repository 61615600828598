import {FormProvider, SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {
    CreateUserFormSchema,
    CreateUserValidationSchema
} from '@/features/users/components/create-user-form/CreateUserFormModel.ts'
import React, {useState} from 'react'
import {DataUserForm} from '@/features/users/components/create-user-form/data-userForm/DataUserForm.tsx'
import {PermissionsUserForm} from '@/features/users/components/create-user-form/permission-user-form/PermissionsUserForm.tsx'
import {useGetPermissions} from '@/features/users/services/query/useGetPermissions.ts'
import {adaptPermissionsToSelect, adaptUserToCreate} from '@/features/users/utils.ts'
import {Button} from '@components/ui/button/Button.tsx'
import {ArrowRightIcon} from '@components/ui/icon/Icon.tsx'
import {useTranslation} from 'react-i18next'
import {StyledCreateUserModalFooter} from '@/features/users/components/create-user-form/style.ts'
import {useCreateUser} from '@/features/users/services/query/useCreateUser.ts'
import toast from 'react-hot-toast'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'

interface CreateUserFormProps {
    onCloseModal: () => void
}
export const CreateUserForm: React.FC<CreateUserFormProps> = ({onCloseModal}) => {
    const {t} = useTranslation()
    const methods = useForm<CreateUserValidationSchema>({
        mode: 'onBlur',
        resolver: zodResolver(CreateUserFormSchema),
        defaultValues: {
            isAssignable: false
        }
    })
    const {remappedData} = useGetPermissions()
    const {mutate: createUser, isPending: isPendingCreate} = useCreateUser({
        onSuccess: () => {
            toast.success(t('users:createModal:form:success'))
            onCloseModal()
        }
    })

    const [formStep, setFormStep] = useState<'data' | 'permissions'>('data')
    const onClickNextStep = () => {
        setFormStep('permissions')
    }
    const onClickPreviousStep = () => {
        setFormStep('data')
    }
    const onSubmit: SubmitHandler<CreateUserValidationSchema> = data => {
        if (formStep === 'data') {
            onClickNextStep()
        } else {
            createUser(adaptUserToCreate(data))
        }
    }
    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                {formStep == 'data' && <DataUserForm />}
                {formStep == 'permissions' && (
                    <PermissionsUserForm permissions={adaptPermissionsToSelect(remappedData)} />
                )}
                <StyledCreateUserModalFooter justify={'space-between'}>
                    {formStep === 'data' ? (
                        <>
                            <Button variant={'ghost'} type={'button'} onClick={onCloseModal}>
                                {t('users:createModal:cancel')}
                            </Button>
                            <Button variant={'primary'} autoFocus={true} onClick={onClickNextStep}>
                                {t('users:createModal:continue')}
                                <ArrowRightIcon />
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button variant={'ghost'} type={'button'} onClick={onClickPreviousStep}>
                                {t('users:createModal:back')}
                            </Button>
                            <Button
                                variant={'primary'}
                                type={'submit'}
                                disabled={!methods.formState.isValid || isPendingCreate}
                            >
                                {t('users:createModal:add')}
                                {isPendingCreate ? <Spinner size={20} /> : <ArrowRightIcon />}
                            </Button>
                        </>
                    )}
                </StyledCreateUserModalFooter>
            </form>
        </FormProvider>
    )
}
