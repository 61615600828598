import styled, {css} from 'styled-components'
import {DESKTOP_TABLE_SCROLLBAR_WIDTH} from '@components/commons/table/desktop-table/style.ts'
import {Virtuoso} from 'react-virtuoso'

export const StyledMessageContainer = styled.div`
    ${({theme: {spacing}}) => css`
        margin-bottom: ${spacing * 4}px;
    `}
`

export const StyledMessageVirtualList: typeof Virtuoso = styled(Virtuoso)(
    ({theme: {palette}}) => css`
        width: 100%;
        &::-webkit-scrollbar {
            width: ${DESKTOP_TABLE_SCROLLBAR_WIDTH};
            background-color: ${palette.neutral[50]};
        }
        &::-webkit-scrollbar-thumb {
            background-color: ${palette.neutral[300]};
            border-radius: 3px;

            &:hover {
                background-color: ${palette.neutral[400]};
            }
        }
    `
)
