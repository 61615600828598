import {parseAxiosPromise} from '@/axiosInstance'
import {QUERY_KEYS} from '@/queryClient'
import {UseInfiniteQueryOptions, useQuery} from '@tanstack/react-query'
import {captureException} from '@sentry/react'
import {z} from 'zod'
import {httpGetAssignable} from '@/features/tickets/services/tickets.http.ts'
import {User} from '@/features/users/types.ts'

export const ResponseShape = z.array(User)
type ResponseShape = z.infer<typeof ResponseShape>

export const useGetAssignable = (
    params?: {searchString?: string},
    options?: Pick<UseInfiniteQueryOptions<ResponseShape>, 'staleTime'>
) => {
    return useQuery({
        queryKey: [QUERY_KEYS.ASSIGNEE, params],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetAssignable(params),
                responseShape: ResponseShape,
                onZodError: captureException
            }),
        ...options
    })
}
