import {useEffect, useState} from 'react'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {
    AlertTriangleIcon,
    CheckIcon,
    Edit01Icon,
    Mail02Icon,
    PhoneIcon,
    Trash01Icon,
    XCloseIcon
} from '@components/ui/icon/Icon.tsx'
import {
    AsideContentWrapper,
    AsideFooter,
    AsideHeader,
    AsideSection
} from '@/features/tickets/components/atoms/TicketsAtoms.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Trans, useTranslation} from 'react-i18next'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {
    StyledCloseTicketInfoContainer,
    StyledReportedLabel,
    StyledUserFullName,
    StyledTicketAsideGrid
} from '@/features/tickets/components/ticket-detail-aside-content/style.ts'
import {capitalize, containsAtLeastOne} from '@utilities/helpers.ts'
import {Ticket} from '@/features/tickets/types.ts'
import dayjs from '@/dayjs.ts'
import {TabButton} from '@components/ui/tab-button/TabButton.tsx'
import {getPermitName} from '@/features/users/utils.ts'
import {useAuthStore} from '@/features/auth/store/store.ts'
import {MessagesList} from '@/features/conversation/components/messages-list/MessagesList.tsx'
import {Attachment} from '@/features/tickets/components/attachment/Attachment.tsx'
import {DepartmentSelect} from '@/features/tickets/components/departments-select/DepartmentsSelect.tsx'
import {AssigneeSelect} from '@/features/tickets/components/assignee-select/AssigneeSelect.tsx'
import {useDeleteTicket} from '@/features/tickets/services/query/useDeleteTicket.ts'
import {StyledAsideTabContainer} from '@/features/tickets/components/ticket-aside/style.ts'
import {useEditTicket} from '@/features/tickets/services/query/useEditTicket.ts'
import {ConfirmModal} from '@components/commons/confirm-modal/ConfirmModal.tsx'
import {Tooltip} from '@components/ui/tooltip/Tooltip.tsx'
import {useGetTicketsDetails} from '@/features/tickets/services/query/useGetTicketsDetails.ts'
import {useTicketAsideStore} from '@/features/tickets/store/useTicketAside.ts'
import {useRemoveDepartment} from '@/features/tickets/services/query/useRemoveDepartment.ts'
import toast from 'react-hot-toast'
import {ScrollArea} from '@components/ui/scroll-area/ScrollArea.tsx'
import {Scrollbar} from '@components/ui/scrollbar/Scrollbar.tsx'
import {useTheme} from 'styled-components'
import {Modal} from '@components/ui/modal/Modal.tsx'
import {
    StyledConfirmModalBody,
    StyledConfirmModalContent,
    StyledConfirmModalFooter
} from '@components/commons/confirm-modal/style.ts'

const contactTypeToLabel = {
    person: 'tickets:contact_type:person',
    phone: 'tickets:contact_type:phone',
    email: 'commons:email',
    chatbot: 'tickets:contact_type:chatbot'
} as const satisfies Record<Ticket['contactType'], string>

export const TicketDetailAsideContent = () => {
    const {t} = useTranslation()
    const ticketId = useTicketAsideStore(state => state.ticketId)
    const setView = useTicketAsideStore(state => state.setView)
    const onCloseAside = useTicketAsideStore(state => state.onCloseAside)
    const user = useAuthStore(state => state.user)
    const [section, setSection] = useState<'report' | 'chat' | 'delegation'>('report')
    const [showModalType, setShowModalType] = useState<'openTicket' | 'closeTicket' | null>(null)
    const [showAlertTicketModal, setShowAlertTicketModal] = useState(false)
    const editTicket = useEditTicket(ticketId || undefined, {
        onSuccess: (_, variables) => {
            if (variables.completedBy) {
                toast.success(t('tickets:close_success'))
                setShowModalType(null)
                onCloseAside()
            }
            if (variables.completedBy === null) {
                toast.success(t('tickets:open_success'))
                setShowModalType(null)
                onCloseAside()
            }
        }
    })
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const ticketQuery = useGetTicketsDetails(ticketId || undefined)
    const removeDepartment = useRemoveDepartment(ticketId || undefined)

    const deleteTicket = useDeleteTicket(ticketQuery.data?.id, {
        onSuccess: () => {
            setShowDeleteModal(false)
            onCloseAside()
        }
    })

    const oncClickMoveToClosed = () => {
        if (user?.id) {
            if (ticketQuery.data?.assignee) {
                if (ticketQuery.data.assignee.id == user.id) {
                    return setShowModalType('closeTicket')
                }
            } else if (ticketQuery.data?.type == 'praise' || ticketQuery.data?.type == 'suggestion') {
                return setShowModalType('closeTicket')
            }
        }
        setShowAlertTicketModal(true)
    }
    const onClickMoveToOpen = () => {
        setShowModalType('openTicket')
    }

    useEffect(() => {
        if (section != 'report') {
            setSection('report')
        }
    }, [ticketQuery.data])

    const onClearDepartment = () => {
        if (ticketQuery.data?.departments[0].id) {
            removeDepartment.mutate({departmentsIds: [ticketQuery.data?.departments[0].id]})
        }
    }

    const onClearAssignee = () => {
        editTicket.mutate({assigneeId: null})
    }

    const {palette} = useTheme()

    return (
        <AsideContentWrapper>
            <AsideHeader fullWidth justify={'space-between'} align={'center'}>
                <h3>#{ticketId}</h3>
                <Button variant={'ghost'} onClick={onCloseAside}>
                    <XCloseIcon />
                </Button>
            </AsideHeader>

            {ticketQuery.isPending ? (
                <AsideSection direction={'column'} gap={4} fullWidth>
                    <Flexbox direction={'column'} gap={3} fullWidth>
                        <Skeleton height={21} width={47} />

                        <Skeleton height={30} width={200} />

                        <Flexbox direction={'column'} gap={2} fullWidth>
                            <Skeleton height={21} width={200} />
                            <Skeleton height={21} width={150} />
                            <Skeleton height={20} width={200} />
                        </Flexbox>
                    </Flexbox>
                    <Skeleton height={32} />
                    <Skeleton height={32} />
                </AsideSection>
            ) : ticketQuery.isError ? (
                <ErrorBox icon={<AlertTriangleIcon />} title={t('errors:default')} />
            ) : (
                <StyledTicketAsideGrid>
                    <AsideSection direction={'column'} gap={4}>
                        <Flexbox direction={'column'} gap={3}>
                            <p>{t(`tickets:table:typology:${ticketQuery.data.type}`)}</p>

                            <StyledUserFullName>
                                {`${capitalize(ticketQuery.data.senderFirstName)} ${capitalize(ticketQuery.data.senderLastName)}`}
                            </StyledUserFullName>

                            <Flexbox direction={'column'} gap={2}>
                                <Flexbox gap={2}>
                                    <Mail02Icon size={20} />
                                    <p>{ticketQuery.data.email}</p>
                                </Flexbox>
                                <Flexbox gap={2}>
                                    <PhoneIcon size={20} />
                                    <p>{ticketQuery.data.phoneNumber}</p>
                                </Flexbox>
                                <StyledReportedLabel>
                                    <Trans
                                        i18nKey={'tickets:aside:reported_from_x_at_x'}
                                        values={{
                                            origin: t(contactTypeToLabel[ticketQuery.data.contactType]),
                                            date: dayjs(ticketQuery.data.createdAt).format('L'),
                                            time: dayjs(ticketQuery.data.createdAt).format('LT')
                                        }}
                                        components={[<b />]}
                                    />
                                </StyledReportedLabel>
                            </Flexbox>
                        </Flexbox>

                        <AssigneeSelect
                            value={ticketQuery.data.assignee}
                            onChange={value => editTicket.mutate({assigneeId: parseInt(value)})}
                            disabled={
                                (editTicket.isPending && !!editTicket.variables.assigneeId) ||
                                !!ticketQuery.data.completedBy
                            }
                            isOptional={ticketQuery.data.type == 'praise' || ticketQuery.data.type == 'suggestion'}
                            onClear={onClearAssignee}
                        />
                        <DepartmentSelect
                            disabled={
                                (editTicket.isPending && !!editTicket.variables.departmentsIds) ||
                                removeDepartment.isPending
                            }
                            value={
                                ticketQuery.data.departments.length > 0 ? ticketQuery.data.departments[0] : undefined
                            }
                            onChange={value => editTicket.mutate({departmentsIds: [parseInt(value)]})}
                            onClear={onClearDepartment}
                        />
                    </AsideSection>
                    <StyledTicketAsideGrid>
                        <StyledAsideTabContainer>
                            <TabButton onClick={() => setSection('report')} $isActive={section === 'report'}>
                                <p>{t('tickets:aside:labels:report')}</p>
                            </TabButton>
                            {user &&
                                containsAtLeastOne(['conversation'], getPermitName(user.permissions)) &&
                                ticketQuery.data.conversationId && (
                                    <TabButton onClick={() => setSection('chat')} $isActive={section === 'chat'}>
                                        <p>{t('tickets:aside:labels:chat')}</p>
                                    </TabButton>
                                )}
                            {ticketQuery.data.attachments.length !== 0 && (
                                <TabButton
                                    onClick={() => setSection('delegation')}
                                    $isActive={section === 'delegation'}
                                >
                                    <p>{t('tickets:aside:labels:delegation')}</p>
                                </TabButton>
                            )}
                        </StyledAsideTabContainer>
                        {section == 'report' ? (
                            <ScrollArea scrollbar={<Scrollbar />}>
                                <AsideSection>
                                    {section === 'report' ? (
                                        <p style={{wordBreak: 'break-word'}}>{ticketQuery.data.content}</p>
                                    ) : section === 'chat' && ticketQuery.data.conversationId ? (
                                        <MessagesList conversationId={ticketQuery.data.conversationId} />
                                    ) : (
                                        ticketQuery.data.attachments.length !== 0 && (
                                            <Flexbox gap={3} direction={'column'} align={'stretch'} fullWidth>
                                                {ticketQuery.data.attachments.map((attachment, key) => (
                                                    <Attachment
                                                        key={key}
                                                        fileName={t(`tickets:aside:attachment:${attachment.type}`)}
                                                        extension={attachment.extension}
                                                        url={attachment.url}
                                                    />
                                                ))}
                                            </Flexbox>
                                        )
                                    )}
                                </AsideSection>
                            </ScrollArea>
                        ) : section === 'chat' && ticketQuery.data.conversationId ? (
                            <AsideSection>
                                <MessagesList conversationId={ticketQuery.data.conversationId} />
                            </AsideSection>
                        ) : (
                            ticketQuery.data.attachments.length !== 0 && (
                                <AsideSection>
                                    <Flexbox gap={3} direction={'column'} align={'stretch'} fullWidth>
                                        {ticketQuery.data.attachments.map((attachment, key) => (
                                            <Attachment
                                                key={key}
                                                fileName={t(`tickets:aside:attachment:${attachment.type}`)}
                                                extension={attachment.extension}
                                                url={attachment.url}
                                            />
                                        ))}
                                    </Flexbox>
                                </AsideSection>
                            )
                        )}
                    </StyledTicketAsideGrid>
                </StyledTicketAsideGrid>
            )}

            <AsideFooter fullWidth justify={'space-between'}>
                {ticketQuery.isPending ? (
                    <>
                        <Skeleton height={40} width={200} />
                        <Skeleton height={40} width={80} />
                    </>
                ) : ticketQuery.data?.completedBy ? (
                    <>
                        <Flexbox gap={2}>
                            <CheckIcon color={palette.success[500]} />
                            <StyledCloseTicketInfoContainer direction={'column'}>
                                <p>{t('commons:closed')}</p>
                                <span>
                                    {t('tickets:by_x_at_x', {
                                        firstName: ticketQuery.data.completedBy.firstName,
                                        lastName: ticketQuery.data.completedBy.lastName,
                                        date: dayjs(ticketQuery.data.completedAt).format('L'),
                                        time: dayjs(ticketQuery.data.completedAt).format('LT')
                                    })}
                                </span>
                            </StyledCloseTicketInfoContainer>
                        </Flexbox>
                        <Button
                            style={{textDecoration: 'underline', flexShrink: 0}}
                            variant={'ghost'}
                            onClick={() => onClickMoveToOpen()}
                        >
                            {t('tickets:table:mark_open')}
                        </Button>
                    </>
                ) : (
                    <>
                        <Button variant={'secondary'} onClick={() => oncClickMoveToClosed()}>
                            <CheckIcon />
                            {t('tickets:table:mark_closed')}
                        </Button>
                        <Flexbox>
                            <Tooltip
                                isOpenOnClickEnabled={false}
                                triggerProps={{asChild: true}}
                                content={
                                    ticketQuery.data?.isInternal
                                        ? t('commons:edit')
                                        : t('tickets:chatbot_ticket_no_edit')
                                }
                            >
                                <Button
                                    disabled={!ticketQuery.data?.isInternal}
                                    onClick={() => setView('edit')}
                                    variant={'ghost'}
                                    shape={'square'}
                                >
                                    <Edit01Icon />
                                </Button>
                            </Tooltip>
                            <Tooltip
                                isOpenOnClickEnabled={false}
                                triggerProps={{asChild: true}}
                                content={
                                    ticketQuery.data?.isInternal
                                        ? t('commons:delete')
                                        : t('tickets:chatbot_ticket_no_delete')
                                }
                            >
                                <Button
                                    disabled={!ticketQuery.data?.isInternal}
                                    onClick={() => setShowDeleteModal(true)}
                                    variant={'secondaryDanger'}
                                    shape={'square'}
                                >
                                    <Trash01Icon />
                                </Button>
                            </Tooltip>
                        </Flexbox>
                    </>
                )}
            </AsideFooter>
            {showDeleteModal && (
                <ConfirmModal
                    modalTitle={t('tickets:delete_modal:delete_ticket')}
                    title={t('tickets:delete_modal:title')}
                    description={t('tickets:delete_modal:description')}
                    onClose={() => setShowDeleteModal(false)}
                    primaryCtaLabel={t('tickets:delete_modal:delete_ticket')}
                    onClickCta={() => deleteTicket.mutate()}
                    isPending={deleteTicket.isPending}
                    ctaVariant={'primaryDanger'}
                />
            )}

            {!!showModalType && (
                <ConfirmModal
                    modalTitle={
                        showModalType == 'closeTicket'
                            ? t('tickets:table:mark_closed')
                            : t('tickets:table:reopen_ticket')
                    }
                    title={
                        showModalType == 'closeTicket'
                            ? t('tickets:close_ticket_modal:title')
                            : t('tickets:open_ticket_modal:title')
                    }
                    description={
                        showModalType == 'closeTicket'
                            ? t('tickets:close_ticket_modal:description')
                            : t('tickets:open_ticket_modal:description')
                    }
                    onClose={() => setShowModalType(null)}
                    primaryCtaLabel={
                        showModalType == 'closeTicket'
                            ? t('tickets:close_ticket_modal:primary_cta')
                            : t('tickets:open_ticket_modal:primary_cta')
                    }
                    onClickCta={() =>
                        showModalType == 'closeTicket'
                            ? editTicket.mutate({completedBy: user?.id})
                            : editTicket.mutate({completedBy: null})
                    }
                    isPending={editTicket.isPending}
                />
            )}

            {showAlertTicketModal && (
                <Modal onOverlayClick={() => setShowAlertTicketModal(false)}>
                    <StyledConfirmModalContent>
                        <StyledConfirmModalBody direction={'column'} gap={2}>
                            <h4>{t('tickets:alert_modal:title')}</h4>
                            <p>{t('tickets:alert_modal:description')}</p>
                        </StyledConfirmModalBody>
                        <StyledConfirmModalFooter justify={'end'}>
                            <Button onClick={() => setShowAlertTicketModal(false)}>{t('commons:okay')}</Button>
                        </StyledConfirmModalFooter>
                    </StyledConfirmModalContent>
                </Modal>
            )}
        </AsideContentWrapper>
    )
}
