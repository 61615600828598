import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledAttachmentContainer = styled(Flexbox)(
    ({theme: {palette, typography, spacing}}) => css`
        padding: ${spacing * 3}px ${spacing * 4}px;
        border: 1px solid ${palette.neutral['300']};
        border-radius: 8px;
        & h4 {
            color: ${palette.neutral['900']};
            font-weight: 500;
            text-overflow: ellipsis;
            overflow: hidden;
            ${typography.textMd}
        }
        & p {
            font-weight: 400;
            ${typography.textXs}
        }
    `
)
export const StyledFileInfoContainer = styled.div`
    overflow: hidden;
`

export const StyleButtonAttachmentLink = styled.a`
    ${({theme}) => css`
        cursor: pointer;
        color: ${theme.palette.primary['700']};
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        font-weight: 600;
        word-break: break-word;
        gap: ${theme.spacing * 2}px;
        padding: ${theme.spacing * 2}px ${theme.spacing * 3.5}px;
        ${theme.typography.textSm};
    `}
`
