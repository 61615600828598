import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledAsideContentWrapper = styled.div(
    () => css`
        display: grid;
        grid-template-rows: auto minmax(0, 1fr) auto;
        height: 100%;
    `
)

export const StyledAsideHeader = styled(Flexbox)(
    ({theme: {spacing, palette, typography}}) => css`
        padding: ${spacing * 3}px ${spacing * 4}px;
        border-bottom: 1px solid ${palette.neutral[300]};
        & h3 {
            ${typography.textMd};
            font-weight: 500;
            color: ${palette.neutral['700']};
        }
    `
)

export const StyledAsideFooter = styled(Flexbox)(
    ({theme: {spacing, palette}}) => css`
        padding: ${spacing * 5}px ${spacing * 4}px;
        border-top: 1px solid ${palette.neutral[300]};
    `
)

export const StyledAsideSection = styled(Flexbox)(
    ({theme: {spacing, palette}}) => css`
        padding: ${spacing * 6}px ${spacing * 4}px ${spacing * 4}px ${spacing * 4}px;
        height: 100%;
        width: 100%;
        & p {
            color: ${palette.neutral[700]};
        }
    `
)
