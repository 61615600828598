import {QUERY_KEYS, queryClient} from '@/queryClient.ts'
import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types/commons.ts'
import {errorHandler} from '@utilities/genericErrorHandler.ts'
import {httpRemoveDepartments} from '@/features/tickets/services/tickets.http.ts'
import {raise} from '@utilities/helpers.ts'

export const useRemoveDepartment = (
    ticketId?: number,
    options?: Omit<UseMutationOptions<unknown, unknown, {departmentsIds: number[]}>, 'mutationFn'>
) => {
    return useMutation({
        ...options,
        mutationFn: payload => httpRemoveDepartments({ticketId: ticketId || raise('ticketId id undefined')}, payload),
        onError: (error: AxiosError<ErrorResponseData>) => {
            errorHandler(error)
        },
        onSuccess: (data, variables, context) => {
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.TICKETS]})
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.TICKET_DETAILS, ticketId]})
            options?.onSuccess?.(data, variables, context)
        }
    })
}
