import i18next from 'i18next'
import toast from 'react-hot-toast'
import {isAxiosError} from 'axios'
import {forwardRef, ReactNode, Ref, RefAttributes} from 'react'

/**
 * Generic errorHandler function
 * @param error
 */
export const errorHandler = (error: unknown) => {
    if (isAxiosError(error)) {
        const message =
            error.response?.data.message && error.status != 500
                ? i18next.t(`errors:${error.response.data.message}`)
                : i18next.t('errors:default')
        return toast.error(message)
    }

    toast.error(i18next.t('errors:default'))
}

export const ObjectKeys = Object.keys as <T extends object>(object: T) => Array<keyof T>
export const ObjectValues = Object.values as <T extends object>(object: T) => Array<T[keyof T]>
export const ObjectEntries = Object.entries as <T extends object>(
    object: T
) => Array<{[K in keyof T]: [K, T[K]]}[keyof T]>

export function genericForwardRef<TRef, TProps = object>(
    render: (props: TProps, ref: Ref<TRef>) => ReactNode
): (props: TProps & RefAttributes<TRef>) => ReactNode {
    return forwardRef(render) as (props: TProps & RefAttributes<TRef>) => ReactNode
}
