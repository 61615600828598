import {z} from 'zod'
import dayjs from '@/dayjs.ts'

export type ErrorResponseData = {
    message: string
}
export const PaginationParams = z.object({
    page: z.number().optional(),
    limit: z.number().optional()
})
export type PaginationParams = z.infer<typeof PaginationParams>

export const ResponseType = z.enum(['small', 'regular'])
export type ResponseType = z.infer<typeof ResponseType>

export const PaginationResponse = z.object({
    total: z.number(),
    last_page: z.number(),
    per_page: z.number(),
    current_page: z.number(),
    next_page_url: z.string().nullish()
})
export type PaginationResponse = z.infer<typeof PaginationResponse>

export const DateRangeParams = z.object({
    startDate: z.string().default(dayjs().locale('IT').startOf('week').toISOString()),
    endDate: z.string().default(dayjs().locale('IT').endOf('week').toISOString())
})
export type DateRangeParams = z.infer<typeof DateRangeParams>

export const HttpExportParams = z.object({
    limit: z.number().optional(),
    page: z.number().optional(),
    ...DateRangeParams.partial().shape,
    responseType: ResponseType.optional()
})
export type HttpExportParams = z.infer<typeof HttpExportParams>
