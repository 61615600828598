import {z} from 'zod'
import {DateRangeParams, PaginationParams, PaginationResponse, ResponseType} from '@/types/commons.ts'
import {User} from '@/features/users/types.ts'
import {Conversation} from '@/features/conversation/types.ts'
import {zfd} from 'zod-form-data'

export const Attachment = z.object({
    id: z.number(),
    ticketId: z.number(),
    name: z.string(),
    extension: z.string(),
    pathLocation: z.string(),
    createdAt: z.string(),
    url: z.string(),
    type: z.enum(['delegation', 'identity_document'])
})
export type Attachment = z.infer<typeof Attachment>

export const ContactType = z.enum(['person', 'phone', 'email', 'chatbot'])
export const TicketType = z.enum(['praise', 'complaint', 'help', 'refund', 'suggestion', 'info'])

export const Ticket = z.object({
    id: z.number(),
    conversationId: z.number().nullable(),
    senderFirstName: z.string(),
    senderLastName: z.string(),
    phoneNumber: z.string(),
    email: z.string().email(),
    patientFirstName: z.string().nullable(),
    patientLastName: z.string().nullable(),
    content: z.string(),
    type: TicketType,
    createdAt: z.string(),
    attachments: z.array(Attachment),
    completedBy: User.nullable(),
    completedAt: z.string().nullable(),
    assignee: User.nullable(),
    departments: z.array(z.object({id: z.number(), name: z.string()})),
    conversation: Conversation.nullable(),
    contactType: ContactType,
    isInternal: z.boolean()
})
export type Ticket = z.infer<typeof Ticket>

export const TicketSmall = Ticket.pick({
    id: true,
    conversationId: true,
    senderFirstName: true,
    senderLastName: true,
    phoneNumber: true,
    email: true,
    patientFirstName: true,
    patientLastName: true,
    content: true,
    type: true,
    createdAt: true,
    completedBy: true,
    completedAt: true,
    contactType: true
})
export type TicketSmall = z.infer<typeof TicketSmall>

export const HttpGetTicketsResponse = z.object({
    data: z.array(Ticket),
    meta: PaginationResponse
})
export type HttpGetTicketsResponse = z.infer<typeof HttpGetTicketsResponse>
export const TicketsSearchParams = z.object({
    searchString: z.string().optional(),
    ...DateRangeParams.partial().shape,
    orderBy: z.enum(['id', 'type', 'createdAt', 'sender', 'patient', 'assignee', 'department']).optional(),
    orderDirection: z.enum(['asc', 'desc']).optional(),
    isCompleted: z.string().optional(),
    assigneeId: z.coerce.number().optional()
})
export type TicketsSearchParams = z.infer<typeof TicketsSearchParams>

export const HttpGetTicketsParams = z.object({
    ...PaginationParams.shape,
    ...TicketsSearchParams.shape,
    responseType: ResponseType
})
export type HttpGetTicketsParams = z.infer<typeof HttpGetTicketsParams>

export const HttpGetTicketDetailsParams = z.object({
    ticketId: z.number(),
    responseType: ResponseType
})
export type HttpGetTicketDetailsParams = z.infer<typeof HttpGetTicketDetailsParams>

export const HttpUpdateTicketBody = z.object({
    completedBy: z.number().nullable(),
    completedAt: z.string().nullish()
})
export type HttpUpdateTicketBody = z.infer<typeof HttpUpdateTicketBody>

export const Departments = z.object({
    id: z.number(),
    name: z.string()
})
type Departments = z.infer<typeof Departments>

export const CreateTicketPayload = zfd.formData({
    contactType: zfd.text(ContactType),
    senderFirstName: zfd.text(),
    senderLastName: zfd.text(),
    patientFirstName: zfd.text().optional(),
    patientLastName: zfd.text().optional(),
    email: zfd.text(z.string().email()),
    phoneNumber: zfd.text(),
    content: zfd.text(),
    type: zfd.text(TicketType),
    identityDocument: z.instanceof(File).optional(),
    delegation: z.instanceof(File).optional(),
    isInternal: zfd.text()
})
export type CreateTicketPayload = z.infer<typeof CreateTicketPayload>

export const EditTicketPayload = zfd.formData({
    contactType: zfd.text(ContactType).optional(),
    senderFirstName: zfd.text().optional(),
    senderLastName: zfd.text().optional(),
    patientFirstName: zfd.text().optional(),
    patientLastName: zfd.text().optional(),
    email: zfd.text(z.string().email()).optional(),
    phoneNumber: zfd.text().optional(),
    content: zfd.text().optional(),
    type: zfd.text(TicketType).optional(),
    identityDocument: z.instanceof(File).optional(),
    delegation: z.instanceof(File).optional()
})
export type EditTicketPayload = z.infer<typeof EditTicketPayload>

export const HttpEditFormTicketParams = z.object({
    data: EditTicketPayload,
    ticketId: z.number()
})
export type HttpEditFormTicketParams = z.infer<typeof HttpEditFormTicketParams>

export const HttpEditTicketParams = z.object({
    data: z.object({
        departmentsIds: z.array(z.number()).optional(),
        assigneeId: z.number().nullish(),
        completedBy: z.number().nullish()
    }),
    ticketId: z.number()
})
export type HttpEditTicketParams = z.infer<typeof HttpEditTicketParams>

export const TicketURLParams = z.object({
    ticketId: z.coerce.number().optional(),
    ...TicketsSearchParams.shape
})
export type TicketURLParams = z.infer<typeof TicketURLParams>

export const HttpGetDepartmentsParams = z.object({
    searchString: z.string().optional(),
    ...PaginationParams.shape
})
export type HttpGetDepartmentsParams = z.infer<typeof HttpGetDepartmentsParams>
