import {SelectItem, SelectPopover, SelectArrow, SelectList, ComboboxList} from '@ariakit/react'
import styled, {css} from 'styled-components'
import {Button} from '@components/ui/button/Button'
import {DefaultTheme} from 'styled-components/dist/types'
import {Virtuoso} from 'react-virtuoso'

export const DESKTOP_TABLE_SCROLLBAR_WIDTH = '4px'

export const makeSelectPopoverBaseStyle = ({palette, shadows, spacing, zIndex}: DefaultTheme) => css`
    background-color: ${palette.neutral[50]};
    border: 1px solid ${palette.neutral[200]};
    border-radius: 8px;
    box-shadow: ${shadows.lg};
    padding: ${spacing * 1.5}px ${spacing * 1.5}px;
    max-height: 400px;
    overflow-y: hidden;
    display: grid;
    grid-template-rows: minmax(0, 1fr);
    z-index: ${zIndex.modal};
`

export const StyledSelectPopover = styled(SelectPopover)(
    ({theme}) => css`
        ${makeSelectPopoverBaseStyle(theme)}
    `
)

export const StyledSelectComboboxPopover = styled(StyledSelectPopover)(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-rows: auto minmax(0, 1fr);
        gap: ${spacing * 1.5}px;
    `
)

export const StyledSelectList = styled(SelectList)`
    max-height: 200px;
`

export const StyledSelectComboboxList = styled(ComboboxList)`
    max-height: 200px;
    overflow-x: hidden;
`

export const makeSelectItemBaseStyle = ({palette, spacing, typography, transition}: DefaultTheme) => css`
    ${typography.textSm};
    display: flex;
    gap: ${spacing * 2}px;
    padding: ${spacing * 2}px ${spacing * 2}px;
    align-items: center;
    cursor: pointer;
    border-radius: 6px;
    margin: 1px 0;
    color: ${palette.neutral[950]};
    overflow-x: hidden;
    text-overflow: ellipsis;
    transition: ${transition};

    &[aria-disabled='true'] {
        color: ${palette.neutral['400']};
    }

    &[aria-selected='true'] {
        font-weight: 500;
        background-color: ${palette.primary['100']};
    }

    &:not([aria-selected='true'])[data-active-item='true'] {
        background-color: ${palette.neutral['100']};
    }
`

export const StyledSelectItem = styled(SelectItem)(
    ({theme}) => css`
        ${makeSelectItemBaseStyle(theme)}
    `
)

export const StyledSelectItemContent = styled.div`
    overflow-x: hidden;
    text-overflow: ellipsis;
    width: 100%;
`

export const StyledSelectTrigger = styled(Button)<{$hasChildren: boolean; $hasError: boolean}>(
    ({theme: {palette, shadows, typography}, $hasChildren, $hasError}) => css`
        justify-content: space-between;
        box-shadow: ${$hasError ? 'none' : shadows.sm};
        outline: 1px solid ${$hasError ? palette.danger['300'] : palette.neutral['300']};
        font-weight: 500;
        ${typography.textMd};
        width: 100%;

        ${!$hasChildren &&
        css`
            color: ${palette.neutral['400']};
        `};

        &:not(:disabled):hover,
        &:focus,
        &[aria-expanded='true'] {
            outline: 1px solid ${$hasError ? palette.danger['300'] : palette.primary['300']};
            box-shadow: ${`${shadows.xs}, 0px 0px 0px 4px ${
                $hasError ? palette.danger['100'] : palette.primary['100']
            }`};
        }
        &:disabled {
            background-color: ${palette.neutral['100']};
        }
        & svg {
            color: ${palette.neutral[500]};
        }
    `
)

export const StyledSelectArrow = styled(SelectArrow)<{$isOpen: boolean}>(
    ({theme: {transition, palette}, $isOpen}) => css`
        fill: ${palette.neutral[500]};
        transition: ${transition};
        margin-left: auto;
        ${$isOpen &&
        css`
            transform: rotate(180deg);
        `}
    `
)

export const StyledSelectVirtualList: typeof Virtuoso = styled(Virtuoso)(
    ({theme: {palette}}) => css`
        height: 200px !important;
        &::-webkit-scrollbar {
            width: ${DESKTOP_TABLE_SCROLLBAR_WIDTH};
            background-color: ${palette.neutral[50]};
        }
        &::-webkit-scrollbar-thumb {
            background-color: ${palette.neutral[300]};
            border-radius: 3px;

            &:hover {
                background-color: ${palette.neutral[400]};
            }
        }
    `
)
