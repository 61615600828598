import {forwardRef, ReactNode} from 'react'
import {DropzoneState} from 'react-dropzone'

interface DropzoneProps {
    children: ReactNode
    className?: string
    state: DropzoneState
}
export const Dropzone = forwardRef<HTMLInputElement, DropzoneProps>(({className, state, children}, ref) => {
    return (
        <div className={className} {...state.getRootProps()}>
            <input ref={ref} {...state.getInputProps()} />
            {children}
        </div>
    )
})

Dropzone.displayName = 'Dropzone'
