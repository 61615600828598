import {
    StyledSlidingModalWrapper,
    StyledSlidingModalHeader,
    ANIMATION_DURATION
} from '@components/commons/sliding-modal/style.ts'
import {CSSProperties, FC, HTMLAttributes, ReactNode} from 'react'
import {Button} from '@components/ui/button/Button.tsx'
import {createPortal} from 'react-dom'
import {XCloseIcon} from '@components/ui/icon/Icon.tsx'

export type SlidingModalState = 'open' | 'closing' | 'closed'

export const handleCloseSlidingModal = (setSlidingModalState: (step: SlidingModalState) => void) => {
    setSlidingModalState('closing')
    setTimeout(() => {
        setSlidingModalState('closed')
    }, ANIMATION_DURATION)
}

export const SlidingModalHeader = (props: HTMLAttributes<HTMLDivElement>) => <StyledSlidingModalHeader {...props} />

export const SlidingModalCloseButton: FC<{setSlidingModalState: (slidingModalState: SlidingModalState) => void}> = ({
    setSlidingModalState
}) => (
    <Button shape="square" onClick={() => handleCloseSlidingModal(setSlidingModalState)} variant={'ghost'}>
        <XCloseIcon />
    </Button>
)

interface SlidingModalProps {
    children: ReactNode
    onOverlayClick?: () => void
    slidingModalState: SlidingModalState
    className?: string
    width: CSSProperties['width']
}
export const SlidingModal: FC<SlidingModalProps> = ({children, slidingModalState, className, width}) => {
    return createPortal(
        <StyledSlidingModalWrapper
            className={className}
            $isModalClosing={slidingModalState == 'closing'}
            $width={width}
        >
            {children}
        </StyledSlidingModalWrapper>,
        document.body
    )
}
