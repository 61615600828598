import {create} from 'zustand'
import {SlidingModalState} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {ANIMATION_DURATION} from '@components/commons/sliding-modal/style.ts'
type View = 'create' | 'edit' | 'detail'
type TicketAsideStore = {
    slidingModalState: SlidingModalState
    view: View
    ticketId: number | null
    ticketTableIndex: number | null
    setSlidingModalState: (state: SlidingModalState) => void
    setView: (view: View) => void
    setTicketId: (id: number | null) => void
    setTicketTableIndex: (index: number | null) => void
    onCloseAside: () => void
}

export const useTicketAsideStore = create<TicketAsideStore>()(set => ({
    slidingModalState: 'closed',
    view: 'detail',
    ticketId: null,
    ticketTableIndex: null,
    setSlidingModalState: state => set({slidingModalState: state}),
    setView: view => set({view: view}),
    setTicketId: id => set({ticketId: id}),
    setTicketTableIndex: index => set({ticketTableIndex: index}),
    onCloseAside: () => {
        set({slidingModalState: 'closing', ticketTableIndex: null})
        setTimeout(() => {
            set({
                slidingModalState: 'closed',
                ticketId: null
            })
        }, ANIMATION_DURATION)
    }
}))
