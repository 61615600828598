import {QueryClient, UseInfiniteQueryResult} from '@tanstack/react-query'

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            networkMode: 'online',
            retry: 1,
            retryOnMount: true,
            retryDelay: 1000,
            staleTime: 600000, //10 min
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            refetchOnReconnect: true
        }
    }
})

export const QUERY_KEYS = {
    USERS: 'users',
    PERMISSIONS: 'permissions',
    TICKETS: 'tickets',
    TICKET_DETAILS: 'ticket-details',
    CONVERSATION: 'conversation',
    MESSAGES: 'messages',
    DASHBOARD: 'dashboard',
    DEPARTMENTS: 'departments',
    ASSIGNEE: 'assignee'
} as const

export const MUTATION_KEYS = {
    LOGIN: 'login',
    FORGOT_PASSWORD: 'forgot-password',
    CREATE_USER: 'create-user',
    CREATE_TICKET: 'create-ticket',
    UPDATE_USER: 'update-user',
    DELETE_USER: 'delete-user',
    CHANGE_PASSWORD: 'change-password',
    UPDATE_TICKET: 'update-ticket'
} as const

export const infiniteQueryFetchNextPage = async (infiniteQuery: UseInfiniteQueryResult) => {
    if (infiniteQuery.hasNextPage && !infiniteQuery.isFetching) {
        await infiniteQuery.fetchNextPage()
    }
}
