import {z} from 'zod'

export const CREATE_USER_FORM_MODEL = {
    firstName: {
        name: 'firstName',
        label: 'users:createModal:form:labels:firstname',
        placeholder: 'users:createModal:form:placeholder:firstname',
        defaultValue: '',
        helpText: ''
    },
    lastName: {
        name: 'lastName',
        label: 'users:createModal:form:labels:lastname',
        placeholder: 'users:createModal:form:placeholder:lastname',
        defaultValue: '',
        helpText: ''
    },
    email: {
        name: 'email',
        label: 'users:createModal:form:labels:email',
        placeholder: 'users:createModal:form:placeholder:email',
        defaultValue: '',
        helpText: ''
    },
    permissions: {
        name: 'permissions',
        label: 'users:createModal:form:labels:permissions',
        placeholder: 'users:createModal:form:placeholder:permissions',
        defaultValue: '',
        helpText: 'users:createModal:form:helpText:permissions'
    },
    isAssignable: {
        name: 'isAssignable',
        label: 'users:createModal:form:labels:isAssignable',
        placeholder: '',
        defaultValue: '',
        helpText: ''
    }
} as const

export const CreateUserFormSchema = z.object({
    firstName: z.string().min(1, {message: 'errors:required'}),
    lastName: z.string().min(1, {message: 'errors:required'}),
    email: z.string().min(1, {message: 'errors:email'}).email({
        message: 'errors:email_valid'
    }),
    permissions: z
        .array(
            z.object({
                label: z.string(),
                value: z.string()
            })
        )
        .min(1, {message: 'users:createModal:form:errors:permissions_error'}),
    isAssignable: z.boolean()
})

export type CreateUserValidationSchema = z.infer<typeof CreateUserFormSchema>
