import {SelectValue} from '@components/commons/select/Select.tsx'
import dayjs from 'dayjs'
import {DateRangeParams} from '@/types/commons.ts'
import i18n from '@/translations/i18n.ts'

export const selectDateOption: SelectValue[] = [
    {
        label: i18n.t('dashboard:select:today'),
        value: 'today'
    },
    {
        label: i18n.t('dashboard:select:yesterday'),
        value: 'yesterday'
    },
    {
        label: i18n.t('dashboard:select:this_week'),
        value: 'this_week'
    },
    {
        label: i18n.t('dashboard:select:last_week'),
        value: 'last_week'
    },
    {
        label: i18n.t('dashboard:select:this_month'),
        value: 'this_month'
    },
    {
        label: i18n.t('dashboard:select:last_month'),
        value: 'last_month'
    },
    {
        label: i18n.t('dashboard:select:this_year'),
        value: 'this_year'
    },
    {
        label: i18n.t('dashboard:select:last_year'),
        value: 'last_year'
    },
    {
        label: i18n.t('dashboard:select:any_date'),
        value: 'any_date'
    }
]

export const selectDateValue: Record<string, Partial<DateRangeParams>> = {
    today: {
        startDate: dayjs().startOf('day').toISOString(),
        endDate: dayjs().endOf('day').toISOString()
    },
    yesterday: {
        startDate: dayjs().subtract(1, 'day').startOf('day').toISOString(),
        endDate: dayjs().subtract(1, 'day').endOf('day').toISOString()
    },
    this_week: {
        startDate: dayjs().locale('IT').startOf('week').toISOString(),
        endDate: dayjs().locale('IT').endOf('week').toISOString()
    },
    last_week: {
        startDate: dayjs().locale('IT').subtract(1, 'week').startOf('week').toISOString(),
        endDate: dayjs().locale('IT').subtract(1, 'week').endOf('week').toISOString()
    },
    this_month: {
        startDate: dayjs().startOf('month').toISOString(),
        endDate: dayjs().endOf('month').toISOString()
    },
    last_month: {
        startDate: dayjs().subtract(1, 'month').startOf('month').toISOString(),
        endDate: dayjs().subtract(1, 'month').endOf('month').toISOString()
    },
    this_year: {
        startDate: dayjs().startOf('year').toISOString(),
        endDate: dayjs().endOf('year').toISOString()
    },
    last_year: {
        startDate: dayjs().subtract(1, 'year').startOf('year').toISOString(),
        endDate: dayjs().subtract(1, 'year').endOf('year').toISOString()
    },
    any_date: {
        startDate: undefined,
        endDate: undefined
    }
}

export const findDateRangeKey = ({startDate, endDate}: Partial<DateRangeParams>): string | null => {
    for (const key in selectDateValue) {
        if (Object.prototype.hasOwnProperty.call(selectDateValue, key)) {
            const {startDate: start, endDate: end} = selectDateValue[key]
            if (dayjs(start).toISOString() === startDate && dayjs(end).toISOString() === endDate) {
                return key
            }
        }
    }
    return null
}

export const getDateOptionByValue = (valueToFind: string | null): string | undefined => {
    for (const option of selectDateOption) {
        if (option.value === valueToFind) {
            return option.value
        }
    }
}
