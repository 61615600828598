import styled, {css, keyframes} from 'styled-components'
import {CSSProperties} from 'react'

export const ANIMATION_DURATION = 500

const slideInUp = keyframes`
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
`

const slideInDown = keyframes`
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(100%);
    }
`

export const StyledSlidingModalWrapper = styled.div<{$isModalClosing: boolean; $width: CSSProperties['width']}>(
    ({theme: {palette, zIndex, shadows}, $isModalClosing, $width}) => css`
        position: fixed;
        top: 0;
        right: 0;
        max-width: ${$width};
        z-index: ${zIndex.aside};
        background: ${palette.neutral.white};
        width: 100%;
        height: 100%;
        border-left: 1px solid ${palette.neutral[300]};
        box-shadow: ${shadows.xl};
        animation: ${$isModalClosing ? slideInDown : slideInUp} ${ANIMATION_DURATION}ms ease;
    `
)

export const StyledSlidingModalHeader = styled.div(
    ({theme: {spacing, palette}}) => css`
        padding: ${spacing * 2.5}px ${spacing * 2}px;
        font-weight: 500;
        color: ${palette.neutral[900]};
        border-bottom: 1px solid ${palette.neutral['300']};
    `
)
