import dayjs from '@/dayjs.ts'
import i18n from 'i18next'
import {TicketFormSchema} from '@/features/tickets/components/ticket-form-aside-content/TicketFormModel.ts'
import {CreateTicketPayload, EditTicketPayload} from '@/features/tickets/types.ts'

export const ticketFormatData = (data: string) => {
    const now = dayjs()
    const diffInSeconds = now.diff(data, 'second')

    switch (true) {
        case diffInSeconds < 60:
            return i18n.t('tickets:table:date:few_second')
        case diffInSeconds < 3600:
            return i18n.t('tickets:table:date:few_minute')
        case diffInSeconds < 86400:
            return i18n.t('tickets:table:date:few_hours')
        case diffInSeconds < 172800:
            return i18n.t('tickets:table:date:two_days')
        default:
            return dayjs(data).format('DD MMM YYYY, HH:mm')
    }
}

export const sizeInMB = (sizeInBytes: number, decimalsNum = 2) => {
    const result = sizeInBytes / (1024 * 1024)
    return +result.toFixed(decimalsNum)
}

export const MAX_UPLOAD_SIZE = 8 // MB
export const ACCEPTED_FILE_TYPES = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpeg'],
    'image/jpg': ['.jpg'],
    'application/pdf': ['.pdf']
}
export const MIN_CONTENT_LENGTH = 20
export const MAX_CONTENT_LENGTH = 2400

export const TICKET_TYPES_MODEL: {value: TicketFormSchema['type']; label: string}[] = [
    {
        value: 'praise',
        label: 'tickets:ticket_form:type:praise'
    },
    {
        value: 'complaint',
        label: 'tickets:ticket_form:type:complaint'
    },
    {
        value: 'suggestion',
        label: 'tickets:ticket_form:type:suggestion'
    },
    {
        value: 'info',
        label: 'tickets:ticket_form:type:info'
    },
    {
        value: 'help',
        label: 'tickets:ticket_form:type:help'
    },
    {
        value: 'refund',
        label: 'tickets:ticket_form:type:refund'
    }
]

export const TICKET_CONTACT_TYPES_MODEL: {value: TicketFormSchema['contactType']; label: string}[] = [
    {
        value: 'phone',
        label: 'tickets:contact_type:phone'
    },
    {
        value: 'person',
        label: 'tickets:contact_type:person'
    },
    {
        value: 'email',
        label: 'tickets:ticket_form:email'
    }
]

export const adaptTicketFromFormToCreateMutation = ({data}: {data: TicketFormSchema}) => {
    const ticketFormData = new FormData()
    Object.entries(data).forEach(([key, value]) => {
        if (key != 'isDelegating') {
            value && ticketFormData.append(key, value as string | Blob)
        }
    })
    ticketFormData.append('isInternal', 'true')
    return CreateTicketPayload.parse(ticketFormData)
}

export const adaptTicketFromFormToEditMutation = ({data}: {data: Partial<TicketFormSchema>}) => {
    const ticketFormData = new FormData()
    Object.entries(data).forEach(([key, value]) => {
        if (key != 'isDelegating') {
            value && ticketFormData.append(key, value as string | Blob)
        }
    })

    return EditTicketPayload.parse(ticketFormData)
}
