import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'

export const InputFilePlaceholder = styled(Flexbox)(
    ({theme: {typography, spacing, palette}}) => css`
        ${typography.textSm};
        color: ${palette.neutral[900]};
        margin-left: ${spacing * 3}px;
    `
)

export const InputFileValue = styled(Flexbox)(
    ({theme: {typography, palette, spacing}}) => css`
        ${typography.textSm};
        width: 100%;
        color: ${palette.neutral[900]};
        margin-left: ${spacing * 3}px;
        font-weight: 500;
        word-break: break-word;
    `
)

export const InputFileHighlightedPlaceholder = styled.span(
    ({theme: {palette}}) => css`
        color: ${palette.primary[600]};
        text-decoration: underline;
        font-weight: 500;
    `
)

export const StyledRemoveFileButton = styled(Button)(
    ({theme: {palette}}) => css`
        color: ${palette.danger[700]};
        flex-shrink: 0;
    `
)

export const StyledOpenTicketModalRadioGrid = styled.div(
    ({theme: {spacing, typography, mediaQueries}}) => css`
        ${typography.textSm};
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
        gap: ${spacing * 2.5}px;

        ${mediaQueries.m} {
            grid-template-columns: 1fr;
        }
    `
)

export const StyledSectionTitle = styled.h4(
    ({theme: {typography, palette}}) => css`
        ${typography.textLg};
        font-weight: 500;
        color: ${palette.neutral[900]};
    `
)
